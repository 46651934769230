import React from 'react'
import {Link} from 'react-router-dom'
import {Terms} from './Terms'
import {PostLogo} from './PostLogo'
import {postTypes} from '../../datas/types.js'

Realisation.propTypes = {
    post:postTypes
}

export default function Realisation({post}) {

  const postId = 'post-' + post.id
  const postClass = post.type + ' animate-logo-wrapper '

    return(
        <article id={postId} className={postClass}>
            <PostLogo post={post} />
            <div className="relative z-10">
                <h3 className="w-[100px] mt-2 mb-0 mx-auto text-center text-[10px] tracking-wider uppercase">
                    <Link to={'/projects/'+ post.slug} title={post.title}>
                        {post.title}
                    </Link>
                </h3>
            </div>
            <div className="modal opacity-0 absolute -top-10 w-full [calc(100%+4rem)] left-0 pointer-events-none transition-all duration-500 p-1 text-center">
                <div className="terms-wrapper text-[10px] break-keep whitespace-nowrap">
                    <Terms post={post} taxonomy="categories" />
                </div>
            </div>
        </article>
    )
}