import React, {useState, useContext} from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import {RoundedButton} from './ThemeButton';
import Fab from '@mui/material/Fab';
import {DatasContext} from '../datas/DatasContext';
import PropTypes from 'prop-types';

function FloatingMenu({menuKey, className}) {

    const [isActive, setIsActive] = useState(null)
    const {localDatas} = useContext(DatasContext)

    if(!localDatas) {
        return <></>
    }
    if(!localDatas.menus) {
        return <></>
    } 
    if(!localDatas.menus[menuKey]) {
        return <></>
    } 
    const menu = localDatas.menus[menuKey]

  return (
    <nav className={`${className}`}>
        <div className={`transition-all duration-300 fixed top-0 left-0 w-full h-[100vh] ${null !== isActive ?
            "opacity-70 pointer-events-auto cursor-pointer" : 
            "opacity-0 pointer-events-none"
            }`}
            onClick={() => {setIsActive(null)}}
        />
        <div className={`${isActive ? "opacity-100 pointer-event-auto" : "opacity-0 pointer-events-none"} transition-all duration-300 fixed bottom-20 w-32 left-1/2 -translate-x-1/2`}>
            <ul className={`flex-col gap-4`}>
                {menu.map((item, index) => {
                    return(
                        <li 
                        className={`${isActive ? "opacity-100 pointer-event-auto" : "opacity-0 pointer-events-none"} delayed-list transition-all duration-300 mb-4`} 
                        key={`${menuKey}-float-item-${index}`}>
                            <RoundedButton
                            href={item.link}
                            title={item.title}
                            target={item.target ? item.target : ''}
                            size="small"
                            fullWidth={true}
                            onClick={() => setIsActive(null)}>
                                {item.title}
                            </RoundedButton>
                        </li>
                    )
                })}
            </ul>
        </div>
        <div className="fixed bottom-8 transition-all duration-300 left-1/2 -translate-x-1/2">
        <Fab
            onClick={() => {setIsActive(!isActive)}}
            variant="extended" 
            size="medium"
            color="default"
            sx={{
                textTransform: 'none',
                fontFamily:'inherit'
            }}
            >
            <NavigationIcon sx={{ mr: 1 }} fontSize="inherit" />
            Navigate
        </Fab>
        </div>
    </nav>
  )
}

FloatingMenu.propTypes = {
    menuKey:PropTypes.string,
    className:PropTypes.string,
} 

export default FloatingMenu
