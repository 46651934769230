import React, {useState} from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function MouseOverPopover({children,title,subTitle}) {

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ pt:1,px:1,fontSize:'12px',fontFamily:'sans-serif'}}>{title}</Typography>
        <Typography sx={{ pb:1,px:1,fontSize:'10px',color:'gray',fontFamily:'sans-serif'}}>{subTitle}</Typography>
      </Popover>
    </div>
  );
}

MouseOverPopover.propTypes = {
  children:PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
  title:PropTypes.string,
  subTitle:PropTypes.string
}

export default MouseOverPopover;