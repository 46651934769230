import React from 'react'

export function Stars({rating, title}) {
    
    const numbers = rating.toString().replace(",", ".").split('.');
    let stars = [];
    const starClasses = 'star h-3 w-3 block bg-gradient-to-l from-[#0000ff] to-pink-900';

    if(Array.isArray(numbers)) {
        if(numbers[0]) {
            for(let i = 0; i < parseInt(numbers[0]); i++) {
                stars.push(<span key={title + 'star' + i} className={`${starClasses} bg-[length:100%_100%]`}></span>)
            }
        }
                
        if(numbers[1]) { 
            const lastStarWidth = parseInt(numbers[1]) * 10
            stars.push(<span 
            key={title + 'partial-star'}
            style={{backgroundSize: lastStarWidth.toString() + '% 100%'}}
            className={starClasses}></span>)
        }
    } else {
        for(let i = 0; i < parseInt(numbers); i++) {
            stars.push(<span key={title + 'star' + i} className={`${starClasses} bg-[length:100%_100%]`}></span>)
        }
    }

    const countEmptyStars = parseInt(5 - stars.length)
    for(let i = 1; i <= countEmptyStars; i++) {
        stars.push(<span key={title + 'empty-star' + i} className={`${starClasses} bg-[length:0%_0%]`}></span>)
    }

    return (
        <span className="stars flex gap-1">{stars}</span>
    )
    
}