import React, {useContext} from 'react'
import {DatasContext} from '../../datas/DatasContext';
import SouthIcon from '@mui/icons-material/South';
import {RoundedButton} from '../../components/ThemeButton';
import { ReactComponent as Waves } from "./waves.svg"
export default function Header() {

  const {localDatas} = useContext(DatasContext)

  let titleParts = []

  if(localDatas.site) {
    if(localDatas.site.title) {
      titleParts = localDatas.site.title.split(' ')
    }
  }
  //titleParts=['c','m','k'];

  const toAnchor = () => {
    const targetElement = document.getElementById("services");
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return(
  <>
  <header className="home-header max-w-screen-2xl mx-auto w-full p-4 sm:p-6 md:p-8 lg:pt-16 md:flex">
    <span className="block title my-2 w-[140px]">
      {(titleParts.length > 0 && titleParts.map((titlePart, index) => {
        return(<span key={index + titlePart} className={`${0 === index ? "border-t" : ""} font-normal uppercase tracking-[.2rem] text-right items-center flex justify-end text-[10px] m-0 px-1 border-gray-500 border-b h-[25px]`}>
          {titlePart}
        </span>)
      }))}
    </span>
    <div className="pt-12 md:px-12 md:py-0 flex-1">
      <h1 className="font-thin max-w-[370px] sm:max-w-full text-2xl lg:text-3xl">
        You need a website or a mobile app.
      </h1>
      <h2 className="text-3xl lg:text-6xl font-thin" itemProp="headline">
        <span className="block mb-6 md:mb-2">I'm a Web Application Developer.</span>
        <RoundedButton size='large' color="secondary" onClick={toAnchor}
          sx={{fontSize:"16px",fontWeight:300,borderRadius:"24px",textTransform:'initial',fontStyle:"italic"}}
          ><span>Check My Services</span>
          <SouthIcon sx={{marginLeft:"4px"}} fontSize='inherit' />
        </RoundedButton>
      </h2>
    </div>
  </header>
  <div className="relative w-full h-[100px]">
      <Waves />
  </div>
  </>
  )
}