import React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

function FlatSelect({name, values, onClick, currentValue}) {
    const StyledMenuList = styled(MenuList)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            gap:'24px 8px',
            display:'flex',
            flexWrap:'wrap'
          },
    }))

    const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
      paddingLeft: '4px',
      fontSize: '14px',
      '&.Mui-selected': {
        pointerEvents:'none',
        backgroundColor: theme.palette.primary.main,
      },
      textTransform: 'none',
      fontWeight:'400',
      [theme.breakpoints.down('md')]: {
        display:'inline-flex',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        paddingLeft:'12px',
        paddingRight:'12px',
        borderRadius:'17px',
        height:'34px',
      },
    }))
  
    return(
      <StyledMenuList disablePadding={true}>
      {values.map((term, index) => {
        return(<StyledMenuItem
        divider={index !== values.length - 1 ? true : false}
        dense={true}
        selected={term.term_id === currentValue}
        onClick={(e) => {onClick({name:name,value:term.term_id,event:e})}}
        key={name + term.term_id + index}
        aria-label={`Filter posts by ${term.name}`}
        >
          {term.name}
        </StyledMenuItem>)
      })}
      </StyledMenuList>
    )
}

FlatSelect.propTypes = {
  name:PropTypes.string,
  values:PropTypes.arrayOf(PropTypes.object),
  onClick:PropTypes.func,
  currentValue:PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
}

export default FlatSelect;