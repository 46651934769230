import React, {useState} from 'react'
import {ThemeButton} from './ThemeButton'
import Dialog from '@mui/material/Dialog'
import {styled} from '@mui/material/styles'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export const StyledDialog = styled(Dialog)(props => ({
    '.MuiPaper-root':{
    backgroundColor:props.theme.palette.primary.main
    }
}))

export function ThemeDialog({children, buttonContent ='Open', buttonClass=null, buttonColor="secondary",  title='', linkForward = null}) {
  
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            {buttonClass ? 
                <button className={buttonClass} aria-hidden={false} aria-label={title} onClick={handleClickOpen}>
                    {buttonContent}
                </button> 
                :
                <ThemeButton color={buttonColor} className="w-full" onClick={handleClickOpen}>
                    {buttonContent}
                </ThemeButton>
            }
            <StyledDialog
            open={open}
            onClose={handleClose}
            >
                {title && <DialogTitle>
                    <span className="font-thin">{title}</span>
                </DialogTitle>}
                {children && <DialogContent>
                    {children}
                </DialogContent>}
                <DialogActions>
                {linkForward && <ThemeButton href={linkForward} title={title} color="secondary" target="_blank" onClick={handleClose} rel="noreferrer,noopener">{title}</ThemeButton>}
                <ThemeButton color={buttonColor} onClick={handleClose}>Close</ThemeButton>
                </DialogActions>
            </StyledDialog>
        </>
    )
}