import React from 'react'
import { Link } from 'react-router-dom'
import {PostLogo} from './posts/PostLogo'
import {Terms} from './posts/Terms'
import Share from './Share'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Fab from '@mui/material/Fab'
import {useVideoDatas} from './posts/Video'
import {PostMetas} from './posts/Post'
import MouseOverPopover from './MouseOverPopover'

function SingularHeader({post}) {

    let externalUrl = null
    let fields = null
    if(post.acf) {
        fields = post.acf
        if(post.acf.url) {
            externalUrl = post.acf.url
        }
    }

    const video = useVideoDatas(post)

    let title = null;
    let excerpt = null;
    const props = {
        post:post,
        video:video,
        fields:fields,
        externalUrl:externalUrl,
    }
    switch(true) {
        case post.type === 'post' && !video:
            title = <PostTitle {...props} />
            break;
        case video :
            title = <VideoTitle {...props} />
            if(video.thumbnail) {
                post.featured_media = video.thumbnail
            }
            break;
        case post.type === 'realisation':
            title = <RealisationTitle {...props} />
            excerpt = <Excerpt  {...props} />
            break;
        default:
            title =  <PostTitle {...props} />
            break;
    }
 

    return(
        <header> 
            <div className="flex flex-wrap items-end sm:items-start p-4 pt-10 sm:p-6 md:p-8 pb-0 mx-auto w-full max-w-screen-2xl">
                <div className="animate-logo-wrapper w-[150px] px-2 md:p-0">
                    <PostLogo post={post} />
                </div>
                <div className={`grow mt-8 md:pl-8 md:mt-0 md:grow-0 w-auto max-w-full truncate`}>
                    {title}
                </div>
                {excerpt && excerpt}
                <div className="flex gap-2 flex-1 absolute z-20 top-16 right-4 flex-row-reverse md:pl-8 md:flex-row md:relative md:top-auto md:right-0 md:gap-4 justify-end h-8 items-center">
                    <Share post={post} />
                    {externalUrl && <Fab  
                    href={externalUrl}
                    color="default"
                    size="small"
                    className="text-[0px] transition-all duration-300 flex items-center py-3 px-4 mx-1 border border-gray-300 rounded-lg hover:bg-gray-200" 
                    rel="customer" 
                    target="_blank" 
                    title={post.title}
                    >
                        <OpenInNewIcon />
                    </Fab >}
                </div>

            </div>
        </header>
    )
}
  
export default SingularHeader;


function VideoTitle({video}) {

    return (
        <>
            {(video.channelTitle || video.title) &&
            <MouseOverPopover title={`${video.channelTitle} • ${video.title}`}>
                <h1 className="px-1 min-h-7 truncate" itemProp="headline">
                    {video.channelTitle &&<span>{video.channelTitle}</span>}
                    {video.title && <span> • <span>{video.title}</span></span>}
                </h1>
            </MouseOverPopover>}
            <p className="truncate flex justify-start gap-4 p-1 border-t border-gray-500 min-h-7 text-[12px]">
                <span>{video.publishedAt}</span>
                <span>{video.viewsCount} views</span>
                <span>{video.duration}</span>
            </p>
            <MouseOverPopover title={video.tags.length > 0 && video.tags.join(' #')}>
            <p className="truncate text-[10px] block m-0 p-1 border-t border-gray-500 min-h-7 truncate">
                in {video.tags.length > 0 && video.tags.map(tag =>{
                    return <span key={video.id + tag} className="inline-block mr-1">#{tag}</span>
                })}
            </p>
            </MouseOverPopover>
        </>
    )
}

function PostTitle({post, fields}) {
    return (
        <div className="w-full">
            {post.title && 
            <h1 className="min-h-7 px-1" itemProp="headline">
                {post.title}
            </h1>}    
            {fields && fields.subtitle && <h2 className="subtitle">{fields.subtitle}</h2>}
            <div className="terms-wradivper text-[10px]">
                {post.categories && <Terms post={post} taxonomy="categories" />}
            </div>
            <PostMetas post={post} className='flex justify-between p-1 text-[10px] border-b border-gray-500' />
        </div>
    )
}

function RealisationTitle({post, fields, externalUrl}) {
    return (
        <div className="min-w-[150px] w-full">
            {post.title && 
            <h1 className="p-1" itemProp="headline">
                {post.title}
            </h1>}    
            {fields && fields.subtitle && <h2 className="subtitle">{fields.subtitle}</h2>}
            <div className="text-[10px] w-full h-[25px]">
                {post.categories && <Terms post={post} taxonomy="categories" />}
            </div>
            {externalUrl &&
                <Link 
                to={externalUrl} 
                className="text-[10px] p-1 h-[25px] w-full flex  items-center truncate" 
                rel="customer" 
                target="_blank" 
                title={post.title}
                >
                    {externalUrl}
                </Link>}
            <div className="text-[10px] p-1 h-[25px] w-full flex items-center  border-t border-gray-500">
                {fields.year && fields.year}
            </div>
        </div>
    )
}

function Excerpt({post}) {

    return (
        <>
            {post.excerpt && <p className={`pt-6 pb-2 md:p-1 md:pl-8 text-[14px] w-full md:max-w-[320px]`} dangerouslySetInnerHTML={{__html:post.excerpt}} />}
        </>
    )
}