import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import IosShareIcon from '@mui/icons-material/IosShare';


export function ThemeSpeedDial({items}) {

  const [speedDialDirection, setSpeedDialDirection] = useState('left')
  const [speedDialTop, setSpeedDialTop] = useState('0')

  useEffect(() => {
    const setDirection = () => {
      const direction = window.innerWidth < 768 ? 'down' : 'left'
      const top = window.innerWidth < 768 ? '92px' : '0'
      setSpeedDialDirection(direction)
      setSpeedDialTop(top)
    }

    const onResize = () => {
      window.addEventListener('resize', () => {
        setDirection()
      })  
    }

    setDirection()
    onResize()
   
    return () => {
      window.removeEventListener('resize',onResize)
    }
  },[])


  return (
    <Box sx={{transform:'translateZ(0px)', flexGrow:1, position:'relative', top:speedDialTop}}>
      <SpeedDial
        ariaLabel="Share"
        direction={speedDialDirection}
        FabProps={{size:"small",color:"default"}}
        icon={<IosShareIcon/>}
      >
        {items.map((action) => (
          <SpeedDialAction
            key={action.title}
            FabProps={{target:"_blank",href:action.link, size:"small",color:"default"}}
            icon={action.logo.dark}
            tooltipTitle={'Share on ' + action.title}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}