import React, {useContext} from 'react'
import { useParams } from 'react-router-dom'
import SingularHeader from '../../components/SingularHeader'
import {SingleRealisationContent} from './SingleRealisationContent'
import Blog from '../../components/Blog'
import WhoAmi from '../../components/WhoAmi'
import { DatasContext } from '../../datas/DatasContext';
import {ThemeBreadcrumbs} from '../../components/ThemeBreadcrumbs';
import {HtmlHead} from '../../components/HtmlHead';

export default function Single() {
  const {realisations} = useContext(DatasContext);
  const { slug } = useParams();
  const post = realisations.find(post => slug === post.slug);

  return(
    <>
    <HtmlHead title={post.title} description={post.excerpt} canonical={'/projects/'+post.slug} />
    <article id={`post-${post.id}`} className="">
      <SingularHeader post={post} />
      <SingleRealisationContent post={post} />
      <Blog id="other-projects" postType="realisation" singlePost={post} template="realisation-row" sectionTitle="Other projects..." />
      <WhoAmi />
      <Blog id="single-playlist" postType="post" template="post-row" className="py-4 md:py-8" samePostTemplate={false} sectionTitle="Playlist" />
    </article>
    <ThemeBreadcrumbs post={post} />
    </>
  )
}