import React from 'react'
import Header from './Header'
import Blog from '../../components/Blog'
import WhoAmi from '../../components/WhoAmi'
import {Skills} from '../../components/Skills';
import {HtmlHead} from '../../components/HtmlHead';

export default function Home() {

  return (
    <>
      <HtmlHead />
      <Header />
      <Blog postType="realisation" hasMenu={true} id="projects" sectionTitle={'Projects'} />
      <WhoAmi />
      <Skills id="skills" sectionTitle="Skills" />
      <Blog postType="post" template="post-row" className="py-8" samePostTemplate={false} id="playlist" sectionTitle="Playlist" />
    </>
  )
}