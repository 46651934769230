
import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { DatasContext } from '../datas/DatasContext'


const StyledBreadCrumbs = styled(Breadcrumbs)(props => (
  {
    'MuiBreadcrumbs-ol':{
      flexWrap:'nowrap',
      listStyleType:'none',
    },
    '.MuiBreadcrumbs-li, li':{
    listStyleType:'none',
    fontSize:'12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth:'100%',
    }
  }
))

export function ThemeBreadcrumbs({post = null}) {
  
  const{localDatas} = useContext(DatasContext)
  const baseUrl = localDatas.site.hostname + '/'
  const {category} = useParams()
  const [maxItems, setMaxitems] = useState(4)

  useEffect(()=> {
    setMaxitems(window.innerWidth < 768 ? 2 : 4)
      
    const onResize = () => {
      window.addEventListener('resize', () => {
        setMaxitems(window.innerWidth < 768 ? 2 : 4)
      })  
    }
    onResize()
   
    return () => {
      window.removeEventListener('resize',onResize)
    }

  },[])

  let firstCategory = {slug:'blog',name:'Blog'}
 
  if(null !== post) {
    
    if('post' === post.type || 'video' === post.type) {
      
      if(post['categories']) {
        if(post['categories'].length > 0) {
            if(post.categories[0].slug === category) {
              firstCategory = post.categories[0]
              firstCategory.url = baseUrl + firstCategory.slug + '/'
            }
        }
      }
    }
    
    if('realisation' === post.type) {
      firstCategory = {url:baseUrl + 'projects/', name:'Projects selection'}
    }
   
  }

  const typographySx = {
    fontSize:'12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
  

  return (
    <div role="presentation" className="bg-mint w-full relative py-2 border-t border-gray-200">
      <div role="presentation" className="mx-auto w-full max-w-screen-2xl px-4 sm:px-6 lg:px-8 *:list-none">
      <StyledBreadCrumbs maxItems={maxItems} aria-label="breadcrumb">
        <Link href={baseUrl} sx={typographySx} underline="hover" color="inherit">Home</Link>
        <Typography color="inherit" sx={typographySx}>{firstCategory.name}</Typography>
        {post && <Typography color="inherit" sx={typographySx}>{post.title}</Typography>}
      </StyledBreadCrumbs>
      </div>
    </div>
  )
}
