import React from 'react'
import Blog from '../../components/Blog'
import WhoAmi from '../../components/WhoAmi'
import {HtmlHead} from '../../components/HtmlHead';

export default function PostsBlog() {

    return (
      <>
        <HtmlHead title="Blog" canonical={'/blog'} />
        <Blog postType="post" />
        <WhoAmi/>
      </>
    )
  }
