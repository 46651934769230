import PropTypes from 'prop-types';

export const termTypes = PropTypes.shape({
    term_id:PropTypes.number,
    name:PropTypes.string,
    slug:PropTypes.string,
    term_group:PropTypes.number,
    term_taxonomy_id:PropTypes.number,
    taxonomy:PropTypes.string,
    description:PropTypes.string,
    parent:PropTypes.number,
    count:PropTypes.number,
    filter:PropTypes.string,
    id:PropTypes.number,
    title:PropTypes.string,
    acf:PropTypes.oneOfType([PropTypes.array,PropTypes.bool,PropTypes.object])
});

export const videoTypes = PropTypes.shape({
    channelTitle: PropTypes.string,
    title: PropTypes.string,
    publishedAt: PropTypes.string,
    viewCount:PropTypes.string,
    viewsCount: PropTypes.string,
    duration: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    type:PropTypes.string,
    slug:PropTypes.string,
    categories:PropTypes.arrayOf(termTypes),
    content:PropTypes.string,
    thumbnail:PropTypes.any,
    videoElId:PropTypes.string,
    localLink:PropTypes.string,
});

export const youtubeVideoTypes = PropTypes.shape({
    kind: PropTypes.string,
    etag: PropTypes.string,
    id: PropTypes.string,
    snippet: PropTypes.shape({
        publishedAt: PropTypes.string,
        channelId: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        thumbnails: PropTypes.shape({
        default: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        medium: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        high: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        standard: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        maxres: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        }),
        channelTitle: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        categoryId: PropTypes.string,
        liveBroadcastContent: PropTypes.string,
        localized: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        }),
        defaultAudioLanguage: PropTypes.string,
    }),
    contentDetails: PropTypes.shape({
        duration: PropTypes.string,
        dimension: PropTypes.string,
        definition: PropTypes.string,
        caption: PropTypes.string,
        licensedContent: PropTypes.bool,
        contentRating: PropTypes.object,
        projection: PropTypes.string,
    }),
    statistics: PropTypes.shape({
        viewCount: PropTypes.string,
        likeCount: PropTypes.string,
        favoriteCount: PropTypes.string,
        commentCount: PropTypes.string,
    }),
    player: PropTypes.shape({
        embedHtml: PropTypes.string,
    }),
    topicDetails: PropTypes.shape({
        topicCategories: PropTypes.arrayOf(PropTypes.string),
    }),
    videoUrl: PropTypes.string,
});

export const postTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  translations:PropTypes.shape({
    fr:PropTypes.string,
    en:PropTypes.string,
  }),
  acf: PropTypes.any,
  slug: PropTypes.string,
  link: PropTypes.string,
  excerpt: PropTypes.string,
  date: PropTypes.string,
  modified: PropTypes.string,
  featured_media: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  categories: PropTypes.arrayOf(
    termTypes
  ),
  post_format: PropTypes.arrayOf(
    termTypes
  ),
  post_tag: PropTypes.arrayOf(
    termTypes
  ),
  images:PropTypes.any,
  video: youtubeVideoTypes
});

export const whoAmiTypes = PropTypes.shape({
    title:PropTypes.string,
    subtitle:PropTypes.string,
    ctas:PropTypes.bool,
    form:PropTypes.string,
    slides:PropTypes.arrayOf(PropTypes.shape({
        image:PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
        title:PropTypes.string,
        subtitle:PropTypes.string,
        content:PropTypes.string
    }))
})
