import React, {useState, useEffect} from 'react'
import {FloatingButton} from './ThemeButton'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import CloseIcon from '@mui/icons-material/Close';
import FlatSelect from './FlatSelect';
import {ThemeRadioGroup} from './ThemeRadioGroup';
import PropTypes from 'prop-types';

import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';


function BlogMenus({id,  setCurrentPosts = null, datas = null, title = null}) {

  const [resetButtonVisible, setResetButtonVisible] = useState(false);
  const [currentValue,setCurrentValue] = useState(null);
  const [sectionEl, setSectionEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [mobileNavTop, setMobileNavTop] = useState(64);
  const [popoverTop, setPopoverTop] = useState(120);
  const [mobileNavOpacity, setMobileNavOpacity] = useState(0);
  const [mobileNavPointerEvents, setMobileNavPointerEvents] = useState('none');

  const termsMenu = () => {
      
    const name = "categories";
    if(!datas) {
      return null
    }

    if(!datas.length) {
      return null
    }

    const terms = new Map()
    datas.forEach(post => {
        if (post[name]) {
          if(post[name].length > 0) {
            post[name].forEach(term => {
                terms.set(term.term_id, term)
            })
          }
        }
    })
    if(terms.length > 0) {
      return null
    } 

    const termsArray = Array.from(terms.values());
    termsArray.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0;
    })

    return termsArray

  }

  const terms = termsMenu();

  const metasMenu = () => {
    const name = "team_or_solo";
    const metas = new Map()
    if(!datas) {
      return null
    }
  
    if(!datas.length) {
      return null
    }
  
    datas.forEach(post => {
      let postProp
      if(post[name]) {
        postProp = post[name]
      } else if(!post[name] && post['acf']) {
        if (post['acf'][name]) {
          postProp = post['acf'][name]
        }
      }
        if(postProp) {
          metas.set(postProp, postProp)
        }
    })
  
    if(0 === metas.length) {
      return null
    } 
  
    const metasArray = Array.from(metas.values());
  
    metasArray.sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0;
    })

    return metasArray
  
  }

  const metas = metasMenu();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollIntoView = () => {
    if(sectionEl) {
      sectionEl.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const filterPosts = ({name, value}) => {

    const filteredPosts = datas.filter((post) => {
        if(false !== PostHasProp(post, name, value)) {
          return post;
        }
        return null;
    })

    setCurrentPosts(filteredPosts);
    setResetButtonVisible(true);
    handleClose(false);
    setCurrentValue(value);
    scrollIntoView();
  }

  const resetAll = () => {
    setCurrentPosts(datas);
    setResetButtonVisible(false);
    handleClose();
    setCurrentValue(null);
    scrollIntoView();
  }

  useEffect(() => {
    const section = document.getElementById(id)
    if(section) {
      setSectionEl(section)
    }
  }, [id]);

  /*Set mobile nav opacity and position according to scroll top and header position*/
  useEffect(() => {

    if(sectionEl) {
 
      const isMobileNavVisible = () => {
          const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
          const {top, bottom} = sectionEl.getBoundingClientRect()
    
          top <= scrollY && bottom  > 0 ? setMobileNavOpacity(1) : setMobileNavOpacity(0);
          top <= scrollY && bottom  > 0 ? setMobileNavPointerEvents("all") : setMobileNavPointerEvents("none");
      }

      const mobileNavPosition = () => {
          const bodyClasses = document.body.classList;
          if(bodyClasses.contains('scroll-down') && !bodyClasses.contains('scroll-offset')) {
            setPopoverTop(75);
            setMobileNavTop(16);
          }
          if(bodyClasses.contains('scroll-up') || bodyClasses.contains('scroll-offset')) {
            setPopoverTop(120);
            setMobileNavTop(64);
          }
      }

      const handleMobileNavVisible = () => {
        window.addEventListener('scroll', () => {
          isMobileNavVisible();
          mobileNavPosition();
        });
      }

      isMobileNavVisible();
      handleMobileNavVisible();
      mobileNavPosition();

      return () => {
        window.removeEventListener('scroll',handleMobileNavVisible);
      }
    }
    
  },[sectionEl]);

  const fields = 
  <>  
    <Box sx={{paddingBottom:"25px"}}>
      <p className="hidden md:flex items-center justify-between text-[10px] mb-1">
        <span className="uppercase inline-block m-0 p-1 border-gray-500 border-b border-t tracking-[.2rem] h-[25px]">{title}</span>
        {resetButtonVisible && 
            <Fab 
            onClick={resetAll} 
            variant="circular" 
            size="small"
            template="action" 
            aria-label="Reset filters"
            color="secondary"
            sx={{position:"absolute",right:0,top:-15,height:30,width:35}}
            >
              <FilterListOffIcon fontSize="5" />
            </Fab>}
      </p>
      {terms && <FlatSelect 
      name="categories" 
      values={terms} 
      onClick={filterPosts} 
      currentValue={currentValue}/>}
    </Box>

    <Box sx={{display:"flex"}}>
      {metas && 
      <ThemeRadioGroup 
      name="team_or_solo" 
      values={metas}
      onChange={filterPosts}
      reset={resetAll}
      currentValue={currentValue} />
      }
    </Box>
  </>

  return (
    <> 
      <div className={`hidden md:block pr-8 top-32 w-[380px] transition-all duration-300 relative top-auto `}>
        <div className="sticky top-[72px]">
          {fields}
        </div>
      </div>
       
      <div 
      style={{top:mobileNavTop,opacity:mobileNavOpacity,pointerEvents:mobileNavPointerEvents}} 
      className={`flex md:hidden w-full justify-center gap-4 transition-all duration-300 fixed top-16 left-1/2 -translate-x-1/2 z-50`}>
        <FloatingButton
          aria-describedby={popoverId} 
          onClick={handleClick}
          variant="extended" 
          size="medium"
          aria-label="Toggle filters"
          >
            <FilterListIcon fontSize="inherit"/>
            <span className="ml-2 capitalize">Filter {title}</span>
        </FloatingButton>
        
        {resetButtonVisible && 
            <Fab 
            onClick={resetAll} 
            variant="circular" 
            size="small"
            template="action" 
            color="secondary"
            aria-label="Reset filters"
            >
              <FilterListOffIcon fontSize="inherit" />
            </Fab>}

        {open &&
          <FloatingButton 
          onClick={handleClose} 
          variant="circular" 
          size="small"
          aria-label="Close filters"
          >
            <CloseIcon />
          </FloatingButton>}
      </div>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: popoverTop, left: 0 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <Fade in={open}>
            <div className="block p-4 w-full"> 
              {fields}
            </div>
          </Fade>
      </Popover>
    </>
  )
}

function PostHasProp(post, name, value) {

  let found = false

  const postProp = getPostProp(post, name)
  
  if (postProp) {
    if("object" === typeof postProp) {
      found = postProp.find((prop) => {return value === prop.term_id})
    }
    if("boolean" === typeof postProp || "number" === typeof postProp || "string" === typeof postProp) {
      found = value === postProp
    }
  }

  return false !== found && undefined !== found && null !== found ? true : false
}

function getPostProp(post, key) {
  if(!post || !key) {
    return
  }
  let postProp = null
  if(post[key]) {
    postProp = post[key]
  } else if(!post[key] && post['acf']) {
    if (post['acf'][key]) {
      postProp = post['acf'][key]
    }
  }

  return postProp
}

BlogMenus.propTypes = {
  id:PropTypes.string.isRequired,
  datas:PropTypes.arrayOf(PropTypes.object),
  setCurrentPosts:PropTypes.func,
  title:PropTypes.string,
}

export default BlogMenus;