import React from 'react'
import { Link } from 'react-router-dom'
import { ThemeButton } from '../ThemeButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {postTypes} from '../../datas/types.js'
import PropTypes from 'prop-types';

PostLink.propTypes = {
    post:postTypes,
    children:PropTypes.oneOfType([PropTypes.node,PropTypes.arrayOf(PropTypes.node),PropTypes.string]),
    isButton:PropTypes.bool,
    props:PropTypes.any
}

export function PostLink({post, children, isButton = false, ...props}) {
   
    if(!post) {
        return <></>
    }

    let firstCategory = 'blog'
    if(post['categories']) {
        if(post['categories'].length > 0) {
            firstCategory = post.categories[0].slug
        }
    }

    let postLink = '/' + firstCategory +'/'+ post.slug

    if('video' === post.type) {
        postLink = post.localLink
    }

    return(
        <>
         {true === isButton ? 
        <ThemeButton href={postLink} title={post.title} {...props}>
            <span className={`flex items-center gap-2`}>
                {children}
                <AddCircleOutlineIcon fontSize="inherit" />
            </span>
        </ThemeButton>
        :
        <Link reloadDocument={true} preventScrollReset={false} to={postLink} title={post.title} {...props}>{children}</Link>
        }
        </>
    )
   
}
