import React from 'react'
import Blog from '../../components/Blog'
import WhoAmi from '../../components/WhoAmi'
import {HtmlHead} from '../../components/HtmlHead';

export default function Realisations() {

    return (
      <>
        <HtmlHead title="Projects" canonical={'/projects'} />
        <Blog postType={'realisations'} />
        <WhoAmi />
      </>
    )
  }
