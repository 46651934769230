import React, {useEffect} from 'react';
import Img from './Img';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PropTypes from 'prop-types';

function VideoPlayer({video, className = 'w-full border-b border-t border-gray-500 pt-[200px] relative', playerWrapperId = null}) {

    const  togglePlayId = `toggle-${video.videoElId}`

    useEffect(()=> {
        if(!video) {
            return
        }

        if(!video.videoElId) {
            return
        }

        const YT = window["YT"]
        if(!YT) {
            return
        }
        if(YT) {
            if(false === Object.hasOwn(YT,'PlayerState') || false === Object.hasOwn(YT,'Player')) {
                return
            }
        }

        const states = YT.PlayerState
        let currentState = null
        const playerWrapper = document.getElementById(playerWrapperId)

        const onPlayerReady = (event) => {
            currentState = event.target.getPlayerState()
        }
  
        const onPlayerStateChange = (event) => {
            
            if(playerWrapper) {
                if(event.data === 1) {
                    playerWrapper.classList.add('active')
                }
                if(event.data === 2) {
                    playerWrapper.classList.remove('active')
                }
            }

            currentState = event.data

        }
       
        const player = new YT.Player(video.videoElId, {
            height: '360',
            width: '640',
            videoId: video.id,
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        })

        const togglePlayEl = document.getElementById(togglePlayId)
        if(togglePlayEl) {
            togglePlayEl.addEventListener('click', (e) => {
            
                e.preventDefault()
                e.stopPropagation()
   
                if(states.PLAYING === currentState) {
                    pauseVideo()
                    return
                }
                if(states.PAUSED === currentState) {
                    playVideo()
                    return
                }
                if(states.ENDED === currentState) { 
                    stopVideo()
                    return
                }
                if(states.UNSTARTED === currentState) {
                    playVideo()
                    return
                }
                if(states.CUED === currentState) {
                    playVideo()
                    return
                }
            })
        }
    

        function playVideo() {
            if((1 === YT.loaded || '1' === YT.loaded) && typeof player.playVideo === 'function' ) {
                player.playVideo()
                toggleOpacity(togglePlayEl)
            }
        }

        function pauseVideo() {
            if((1 === YT.loaded || '1' === YT.loaded) && typeof player.pauseVideo === 'function' ) {
                player.pauseVideo()
            }
        }

        function stopVideo() {
            if((1 === YT.loaded || '1' === YT.loaded) && typeof player.stopVideo === 'function' ) {
                player.stopVideo()
                toggleOpacity(togglePlayEl)
            }
        }

    },[video, togglePlayId, playerWrapperId])


    if(!video) {
        return
    }

    return(<figure aria-labelledby={`video-caption-${video.id}`} className={className}>
            <div id={video.videoElId} className="absolute top-0 left-0 h-full w-full *:h-full *:w-full" />
            <div id={togglePlayId} className="thumbnail-wrapper block absolute top-0 left-0 h-full w-full z-10 cursor-pointer">
                <Img image={video.thumbnail} alt={video.title} className="block h-full w-full object-cover" />
                <span 
                className="flex items-center justify-center h-[55px] w-[55px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-green-400 hover:bg-green-600 shadow-xl rounded-full">
                    <PlayArrow sx={{ height: 40, width: 40 }} />
                </span>
            </div>
        </figure>)
}

VideoPlayer.propTypes = {
    className:PropTypes.string,
    playerWrapperId:PropTypes.string,
    video:PropTypes.shape({
        videoElId:PropTypes.string,
        id:PropTypes.string,
        title:PropTypes.string,
        thumbnail:PropTypes.shape({
            url:PropTypes.string,
            src:PropTypes.string,
            width:PropTypes.number,
            height:PropTypes.number
        })
    })
}

function toggleOpacity(el) {
    toggleClass(el,'opacity-0')
    toggleClass(el,'pointer-events-none')
}

function toggleClass(el, cssClass) {
    if(el.classList.contains(cssClass)) {
        el.classList.remove(cssClass)
    } else {
        el.classList.add(cssClass)
    }
}

export default VideoPlayer;