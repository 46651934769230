import React from 'react';
import {HtmlHead} from '../../components/HtmlHead';

export default function Error() {
  return (
    <>
    <HtmlHead canonical={'/404'} />
    <div className="flex items-center justify-center h-[100svh]">
      <h1 className="text-4xl">404</h1>
    </div>
    </>
  )
}