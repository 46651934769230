'use server';

import axios from 'axios';

export async function wordpressPosts(endpoint) {

  try {

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/datas?endpoint=${endpoint}`, {
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
      }
    });

    return response.data;
    
  } catch (error) {
    console.error(error);
  }

}