import React, {useContext} from 'react'
import { SectionTitle } from './SectionTitle'
import { ThemeContact } from './ThemeContact'
import {DatasContext} from '../datas/DatasContext'
import {ThemeTabs} from './ThemeTabs'
import PropTypes from 'prop-types'

function WhoAmi({id = 'services', sectionTitle = 'Services'}) {

    const {whoAmi} = useContext(DatasContext)

    return (
        <article id={id} className="py-4 md:py-8">
            {sectionTitle && <SectionTitle title={sectionTitle} />}
            <div className="px-4 pt-0 sm:px-6 lg:px-8 md:grid md:grid-cols-3 md:gap-4 max-w-screen-2xl mx-auto">
                <div className="col-span-2"> 
                    {whoAmi && whoAmi.slides && <ThemeTabs tabs={whoAmi.slides} />}
                </div>
                <div className="contact">
                    <div className="mt-[40px] md:mt-[54px] flex-none">
                        <ThemeContact />
                    </div>
                </div>
            </div>
        </article>
    )

}

WhoAmi.propTypes = {
    id:PropTypes.string,
    sectionTitle:PropTypes.string
}

export default WhoAmi
