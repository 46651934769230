import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import {styled} from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(props => ({
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    '&.Mui-expanded': {
      marginTop: '0',
    },
}))


const StyledAccordionSummary = styled(AccordionSummary)(props =>({
    minHeight:'32px',
    padding:'0',
    display:'flex',
    alignItems:'center',
    borderBottom:'1px solid',
    '&.Mui-expanded':{
        minHeight:'32px',
        margin:'0'
    },
    '.MuiAccordionSummary-content': {
        letterSpacing: '.02em',
        fontSize: '14px',
        margin:'0',
    }, 
    '.MuiAccordionSummary-expandIconWrapper svg': {
        height: '20px',
        width: '20px',
    }
}))

const StyledAccordionDetails = styled(AccordionDetails)(props =>({
    letterSpacing: '.02em',
    fontSize: '14px',
    margin:'0',
    paddingLeft: '0',
    paddingRight: '0',
}))


export function ThemeAccordion({children, title = null}) {
    return(<>
        {children &&
            <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ArrowDownward />}>
                {null === title ? 'Readmore' : title}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                {children}
            </StyledAccordionDetails>
            </StyledAccordion>
        }
    </>)
}