import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
 
function Gallery({
    galleryIsActive = null,
    setgalleryIsActive = null,
    galleryActiveSlide = null,
    title ='Gallery',
    imagesDesktop = null,
    imagesMobile = null,
}) {

    const [currentTitle, setTitle] = useState(title)
   
    useEffect(()=> {
        let swiperSlides = null
        let currentSlide = null

        
        const handleSwiperEl = () => {
        
            const swiperEl = document.querySelector('.gallery swiper-container')
    
            if(!swiperEl) {
                return
            }
    
            const swiperObj = swiperEl.swiper
    
            if(!swiperObj) {
                return
            }
    
            swiperSlides = swiperObj.slides
    
            if(!swiperSlides) {
                return
            }
    
            if(null !== galleryActiveSlide) {
                const activeSlide = swiperSlides.find(slide => { 
                    return galleryActiveSlide === slide.dataset.id
                })
                if(activeSlide) {
                    const from = swiperObj.realIndex
                    const to = activeSlide.dataset.swiperSlideIndex
                    const speed = Math.abs(to - from) * 300
                    swiperObj.slideToLoop(activeSlide.dataset.swiperSlideIndex, speed)
                }
            }
    
            currentSlide = swiperSlides[swiperObj.realIndex]
    
            if(currentSlide) { 
                if(currentSlide.dataset.caption) {
                    setTitle(currentSlide.dataset.caption)
                }
            }
        }

        handleSwiperEl()
    },[galleryActiveSlide])

    return(
        <div 
        className={`gallery fixed top-0 left-0 w-full h-full z-[50] bg-gray-50 ${null !== galleryIsActive ?
        "opacity-100 pointer-events-auto cursor-pointer" : 
        "opacity-0 pointer-events-none"
        }`}>
            <div className="modal-header text-gray-950 flex items-center relative pl-4 md:pl-6 lg:pl-8 pr-16 h-12 xl:h-14 bg-gray-200">
                <span>{currentTitle}</span>
                <button 
                title='Close modal'
                aria-label='Close modal'
                className='absolute top-2 right-4 h-8 w-8 transition-all hover:bg-gray-100 duration-300 flex items-center justify-center border border-gray-300 rounded-full picto' 
                onClick={() => setgalleryIsActive(null)}
                ><CloseIcon /></button>
            </div>
            <div className="modal-content p-4 md:p-6 lg:p-8">
                <swiper-container 
                    slides-per-view='auto'
                    loop={true}
                    space-between={0}
                    mousewheel-enabled={true}
                    mousewheel-sensitivity={1}
                    style={{
                        height: 'calc(100svh - 110px)'
                    }}>
                    {imagesDesktop.map((image) => {
                        return (
                        <swiper-slide key={`gallery-${image.id}`} data-id={`${image.id}`} data-caption={image.caption} style={{height: 100 + '%'}}>
                            <img
                                src={image.url}
                                alt={image.caption}
                                className="w-full h-full cursor-pointer object-contain"
                            />
                        </swiper-slide>
                        )
                    })}
                    {imagesMobile.map((image) => {
                        return (
                        <swiper-slide key={`gallery-${image.id}`} data-id={`${image.id}`} style={{height: 100 + '%'}}>
                            <img
                                src={image.url}
                                alt={image.caption}
                                className="w-full h-full cursor-pointer object-contain"
                            />
                            {image.caption && 
                             <figcaption>{image.caption}</figcaption>
                            }
                        </swiper-slide>
                        )
                    })}
                </swiper-container>
            </div>
        </div>
    )
}

export default Gallery;
