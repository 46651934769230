import React from 'react'
import { ThemeSpeedDial } from './ThemeSpeedDial'
import RedditIcon from '@mui/icons-material/Reddit'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook'

export default function Share({post}) {
    
    const currentUrl = encodeURI(window.location.href)
    const postTitle = encodeURI(post.title)
    const shareItems = [
        {
        title:'Linkedin',
        link:`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`,
        logo:{
            light:<LinkedInIcon/>,
            dark:<LinkedInIcon/>
            }
        },
        {
        title:'Reddit',
        link:`https://reddit.com/submit?url=${currentUrl}&title=${postTitle}`,
        logo:{
            light:<RedditIcon/>,
            dark:<RedditIcon/>
            }
        },
        {
        title:'Facebook',
        link:`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
        logo:{
            light:<FacebookIcon/>,
            dark:<FacebookIcon/>
            }
        },
         /*{
        title:'X',
        link:`https://twitter.com/intent/tweet?text=${postTitle}&url=${currentUrl}`,
        logo:{
            light:<TwitterIcon/>,
            dark:<TwitterIcon/>
            }
        },*/
        /*{
        title:'Whatsapp',
        link:`https://api.whatsapp.com/send?text=${postTitle}%20${currentUrl}`,
        logo: {
            light:<WhatsAppIcon/>,
            dark:<WhatsAppIcon/>
            }
        },*/
    ]


    

    return <ThemeSpeedDial items={shareItems}/>
}