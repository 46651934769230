import React, {useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import { DatasContext } from '../datas/DatasContext';
import DOMPurify from 'dompurify';

export function HtmlHead({title = null, description = null, canonical = null, imageUrl = null}) {
    const {localDatas} = useContext(DatasContext);

    const baseUrl = DOMPurify.sanitize(process.env.REACT_APP_BASE_URL);
    const metaCanonical = canonical ?  baseUrl + DOMPurify.sanitize(canonical) : baseUrl + '/';

    const metaTitle = title ? DOMPurify.sanitize(title +' | '+ localDatas.site.title) : localDatas.site.title;
    const metaDescription = description ? DOMPurify.sanitize(description , { FORBID_ATTR: ["style","class"], FORBID_TAGS: ["p","style","div"]} ) : localDatas.site.description;

    const metaImage = imageUrl ? DOMPurify.sanitize(imageUrl) : baseUrl + '/img/logo-blk.svg';
    return(
    <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={metaCanonical} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={metaCanonical} />
        <meta property="og:image" content={metaImage} />
    </Helmet>
    )
}

function postMetas() {
    return(
    <>
    <meta property="og:type" content={null} />
    <meta property="og:article:published_time" content={null} />
    <meta property="og:article:modified_time" content={null} />
    <meta property="og:article:author" content={null} />
    <meta property="og:article:section" content={null} />
    <meta property="og:article:tag" content={null} />
    </>
    )
} 