import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
import Menu from './Menu'
import {DarkModeSwitch} from './DarkModeSwitch'
import FloatingMenu from './FloatingMenu'
import {DatasContext} from '../datas/DatasContext'

export default function Header() {

    const {localDatas} = useContext(DatasContext)

    useEffect(() => {
        scrollDirection()
    }, [])

    return (
    <>
        <Link className="hidden skip-link screen-reader-text" to="#content">Skip to content</Link>
        <header key="main-header" role="banner" className="main-header h-12 xl:h-16 transition-all duration-500 bg-gray-200 fixed w-full z-50 shadow-sm">
            <nav className="mx-auto flex max-w-screen-2xl items-center justify-between p-4 py-2 sm:px-6 lg:px-8">
                <Logo/>
                <Menu 
                datas={localDatas}
                menuKey="mainMenu"
                className='hidden md:relative md:flex md:flex-1 gap-3 items-center justify-left px-6 lg:px-8'
                itemClass="p-0 mb-0"
                />
                <div className="px-4 xl:flex-1"><DarkModeSwitch /></div>
                <Menu 
                datas={localDatas} 
                menuKey="ctaMenu"
                className="flex justify-end items-center grid grid-cols-2 gap-3"
                itemClass="p-0 mb-0"
                />
            </nav>
            <FloatingMenu menuKey="mainMenu" className="md:hidden" />
        </header>
    </>
    )
}

function scrollDirection() {
  
      const offset = 32

      window.addEventListener('load', () => {
          if(window.scrollY > offset) {
              document.body.classList.add('scroll-up')
              document.body.classList.remove('scroll-down')
          } else {
            document.body.classList.add('scroll-offset')
          }
      })
      
      let lastScrollTop = 0

      window.addEventListener('scroll', function() {
        if (window.scrollY > lastScrollTop ) {
          document.body.classList.add('scroll-down')
          document.body.classList.remove('scroll-up')
          
        } else {
          document.body.classList.add('scroll-up')
          document.body.classList.remove('scroll-down') 
        }
  
        if(window.scrollY <= offset) {
          document.body.classList.add('scroll-offset')
        } else {
          document.body.classList.remove('scroll-offset')
        }
  
        lastScrollTop = window.scrollY
      })
   
  }