import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary:'#020617',
      secondary:'#8b8b8b',
      disabled:'#b3b3b3',
    },
    action:{
      active:'#adffc',
      hover:'#adffc7',
      selected:'#47a667',
      disabled:'#b3b3b3'
    },
    primary: {
      light: '#cefedd',
      main: '#adffc7',
      dark: '#adffc7',
      contrastText: '#020617',
    },
    secondary: {
      light: '#77d4fe',
      main: '#170fd6',
      dark: '#0075c0',
      contrastText: '#ffffff',
    },
    default:{
      light: '#f9fafc',
      main: '#e5e7eb',
      dark: '#adffc7',
      contrastText: '#000000',
    },
    grey:{
      light: '#c2c2c2',
      main: '#dedede',
      dark: '#818181',
      contrastText: '#000000',
    }
  },
  typography: {
    fontFamily:['ui-sans-serif', 'system-ui', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
})

export function ThemePalette({children}) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}