import React, {useState} from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import DOMPurify from 'dompurify'

function CustomTabPanel({children, currentValue, value, index}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== currentValue}
      id={value}
      aria-labelledby={`${index} ${value}`}
    >
      {value === currentValue && <Paper sx={{ p: 3 }}>{children}</Paper>}
    </div>
  )
}

export function ThemeTabs({tabs, filterTabContent = true, smallTabs = false}) {
  
  const [currentValue, setCurrentValue] = useState(tabs[0].title)
  const handleChange = (event, newValue) => {
    setCurrentValue(newValue)
  }

  const tabSx = (index) => {
    return smallTabs === false ? {
      fontSize:'24px',
      fontFamily: 'inherit',
      fontWeight:'300',
      display:'inline-block',
      color:'#818181',
      '&:before':{
        display:'inline-block',
        content:`"${index + 1}"`,
        fontSize:'14px',
        position:'relative',
        marginRight:'4px'
      },
      '&.Mui-selected':{
        color:'#494949',
      },
    } :
    {
      fontSize:'20px',
      fontFamily: 'inherit',
      fontWeight:'300',
      display:'inline-block',
      color:'#818181',
      textTransform:'unset',
      '&.Mui-selected':{
        color:'#494949',
      }
    };
}

  


  return (
    <Box sx={{ width: '100%' }}>

      <Tabs
        value={currentValue}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
        color="grey"
        sx={{'.MuiTabs-indicator':{
          backgroundColor:'#494949',
        }}}
      >
        {tabs.map((tab, index) => {
          const sx = tabSx(index);
           return (<Tab
            key={index + tab.title}
            index={index}
            value={tab.title}
            checked={currentValue === tab.title}
            label={`${tab.title}`}
            wrapped
            sx={sx}
          />)
        })}
      </Tabs>
      
      {tabs.map((tab, index) => {

        let tabContent = null;
        if(true === filterTabContent) {
          tabContent = DOMPurify.sanitize(tab.content, { FORBID_ATTR: ["style","class"], FORBID_TAGS: ["script","style","div"] })
          tabContent = <section className="content" dangerouslySetInnerHTML={{__html: tabContent}} />
        } else {
          tabContent = tab.content;
        }

        return(
            <CustomTabPanel key={'panel-' + index + tab.title} currentValue={currentValue} value={tab.title} index={index}>
              {tabContent}
            </CustomTabPanel>
        )

      })}
     
    </Box>
  )
}