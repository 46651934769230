import React from 'react'
import { Terms } from './Terms'
import { PostLink } from './PostLink'
import Img from '../Img'
import { ThemeAccordion } from '../ThemeAccordion'
import {postTypes} from '../../datas/types.js'
import PropTypes from 'prop-types';

Post.propTypes = {
    post:postTypes
}

PostMetas.propTypes = {
    post:postTypes,
    className:PropTypes.string
}

export function Post({post}) {

  const postId = 'post-' + post.id
  const postClass = post.type
  const postTitle = post.title.replace("&rsquo;","’")
  
    return(
        <article id={postId} className={`w-64 mb-8 overlfow-hidden ${postClass}`}>
            <h3 className="w-full p-1 border-gray-500 border-t border-b text-[14px] tracking-wider h-[30px] truncate">
                <PostLink post={post}>
                    {postTitle}
                </PostLink>
            </h3>
            <PostMetas post={post} />
            <figure className='w-full pt-[200px] relative'>
                {(post.featured_media !== null && post.featured_media !== undefined)  &&
                    <PostLink post={post} className="absolute block w-full h-full top-0 left-0">
                        <Img image={post.featured_media} alt={postTitle} className="block w-full h-full object-cover" />
                    </PostLink>
                }
            </figure>
            <div className="terms-wrapper text-[10px]">
                <Terms post={post} taxonomy="categories" before="in " prefix="#" />
            </div>
            <figcaption id={`video-caption-${postId}`}>
                {post.excerpt &&
                    <ThemeAccordion title="Details">
                        <div className="content" dangerouslySetInnerHTML={{__html:post.excerpt}} />
                        <div className="pt-2 flex justify-end">
                        <PostLink post={post} isButton={true}>
                            Read
                        </PostLink>
                        </div>
                    </ThemeAccordion>
                }
            </figcaption>
        </article>
    )
}

export function PostMetas({post, className = 'flex justify-between p-1 text-[12px] border-b border-gray-500'}) {

      return(
        <>
            {(post.date || post.author) && 
            <p className={className}>
                {post.date && <span>{post.date}</span>}
                {post.author && <span>{post.author}</span>}
            </p>}
        </>
      )
  }