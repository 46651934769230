import React, {useContext} from 'react'
import { useParams } from 'react-router-dom'
import SingularHeader from '../../components/SingularHeader'
import Blog from '../../components/Blog'
import WhoAmi from '../../components/WhoAmi'
import DOMPurify from 'dompurify'
import Img from '../../components/Img'
import { DatasContext } from '../../datas/DatasContext'
import {ThemeBreadcrumbs} from '../../components/ThemeBreadcrumbs'
import VideoPlayer from '../../components/VideoPlayer'
import {useVideoDatas} from '../../components/posts/Video'
import PropTypes from 'prop-types';
import {HtmlHead} from '../../components/HtmlHead';

export default function SinglePost() {

  const {posts} = useContext(DatasContext)
  const {category, slug} = useParams();

  const post = posts.find(post => slug === post.slug);

  let postContent = null
  if(post.content) {
    postContent = DOMPurify.sanitize(post.content, { FORBID_ATTR: ["style","class"], FORBID_TAGS: ["style","div"] })
  }

  return(
    <>
    <HtmlHead title={post.title} description={post.excerpt} canonical={'/'+category+'/'+post.slug} />
    <article id={`post-${post.id}`} className="">
      <SingularHeader post={post} />
      <FeaturedMedia post={post} />
      <div className="mx-auto w-full max-w-3xl content p-8" dangerouslySetInnerHTML={{__html: postContent}} />
    </article>
    <Blog id="single-playlist" postType="post" singlePost={post} template="post-row" className="py-8" samePostTemplate={false} sectionTitle="More readings" />
    <WhoAmi />
    <ThemeBreadcrumbs post={post} />
    </>
  )
}


export function FeaturedMedia({post}) {
  
  const playerWrapperId = `featured-media-${post.id}`
  let video = useVideoDatas(post)
  if(!post) {
    return <></>
  }

  const media = video ?  
    <VideoPlayer video={video} className="w-full pt-[52.6%] relative" playerWrapperId={playerWrapperId} />
    :
    (post.featured_media ? <Img image={post.featured_media} alt={post.title} className="h-full w-full object-cover" /> : null)
  
  return(
    <div className='max-w-full'>
      {media && 
      <figure id={playerWrapperId} className={`type-${post.type} mx-auto single-post-thumb shadow-post-dark hover:shadow-post-hover dark:shadow-post-light`}>
        {media}
      </figure>}
    </div>
  )
} 

FeaturedMedia.propTypes = {
  post:PropTypes.object.isRequired
}
