import React from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import {DatasContext} from '../datas/DatasContext'

export default function Logo() {

    const {localDatas} = useContext(DatasContext)

    return(
        <Link reloadDocument={true} preventScrollReset={false} to={localDatas.site.hostname} title={localDatas.site.fullTitle} className="block h-8 xl:h-12 xl:w-12 w-8 mr-2 transition-all duration-300">
            <img
                src="/img/logo-blk.svg"
                alt={localDatas.site.fullTitle}
                loading="lazy"
                className="block h-full w-full"
            />
        </Link>
    )
}