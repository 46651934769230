import React, { useEffect, useState, useContext } from 'react'
import Realisation from './posts/Realisation'
import {Post} from './posts/Post'
import {Video} from './posts/Video'
import { SectionTitle } from './SectionTitle'
import { DatasContext } from '../datas/DatasContext'
import BlogMenus from './BlogMenus'
import PropTypes from 'prop-types'


Blog.propTypes = {
  postType:PropTypes.string,
  sectionTitle:PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
  filterByKey:PropTypes.string,
  filterByValue:PropTypes.string,
  invertFilter:PropTypes.bool,
  singlePost:PropTypes.object,
  template:PropTypes.string,
  className:PropTypes.string,
  id:PropTypes.string,
  samePostTemplate:PropTypes.bool,
  hasMenu:PropTypes.bool
}

function Blog({
  postType = 'post',
  sectionTitle=null,
  filterByKey=null,
  filterByValue=null,
  invertFilter=false,
  singlePost=null,
  template='grid',
  className=null,
  id=null,
  samePostTemplate = true,
  hasMenu = false
}) {

  const {posts, realisations} = useContext(DatasContext)
  let datas = postType === 'post' ? posts : realisations

  if(filterByKey && filterByValue) {
      datas = BlogfilterBy(datas, filterByKey, filterByValue, invertFilter)
  }

  if(filterByKey && singlePost) {
    if(Object.hasOwn(singlePost,filterByKey)) {
      datas = BlogfilterBy(datas, filterByKey, singlePost[filterByKey], invertFilter)
      datas = datas.filter(post =>  post.id !== singlePost.id)
    }
  }

  if(!filterByKey && singlePost) {
      datas = datas.filter(post =>  post.id !== singlePost.id)
  }

  const [currentPosts, setCurrentPosts] = useState(datas)


  useEffect(() => {

    const slider = document.querySelector('#'+id + ' swiper-container')
    if(slider) {
      Object.assign(slider, {
        slidesPerView:'auto',
        spaceBetween:'post-row' === template ? 20 : 40,
        freeMode:{
          enabled:true,
          momentumRatio:1.3,
          momentumBounceRatio:1,
          momentumVelocityRatio:1.2,
        },
        mousewheel:{
          enabled:true,
          sensitivity:1,
          releaseOnEdges:true,
          forceToAxis:true
        }  
      })

      slider.initialize()
    }
   
  }, [id, template])

  if(!currentPosts) {
    return <></>
  }

  if(0 === currentPosts.length) {
    return <></>
  }

  const setPostEls = () => {
    
    if(true === samePostTemplate) {
      
      if('realisation' === postType) {
        return currentPosts.map((post,index) => (
          <Realisation key={`post-${post.type}-${post.id}-${index}`} post={post} />
        ))
      }

      if('video' === postType){
        return currentPosts.map((post,index) => (
          <Video key={`post-${post.type}-${post.id}-${index}`} post={post} />
        ))
      }

      if('post' === postType) {
        return currentPosts.map((post,index) => (
          <Post key={`post-${post.type}- ${post.id}-${index}`} post={post} />
        ))
      }

    } else {
      return currentPosts.map((post,index) => {

        if('realisation' === post.type) {
          return <Realisation key={`post-${post.type}-${post.id}-${index}`} post={post} />
        }
        if('video' === post.type){
          
          return <Video key={`post-${post.type}-${post.id}-${index}`} post={post} />
        }
        if('post' === post.type) {
          return  <Post key={`post-${post.type}-${post.id}-${index}`} post={post} />
        }

        return <></>
      })
    }

  }

  const postEls = setPostEls()

  return (
    <section id={id} className={`${className} template-${template} blog block`}>
      {sectionTitle && <SectionTitle title={sectionTitle} className="p-4 sm:p-6 lg:p-8 text-3xl lg:text-4xl font-thin" />}
      
      {'grid' === template  &&
        <div className="p-4 sm:p-6 lg:p-8 max-w-screen-2xl mx-auto w-full z-50 flex gap-16">
          
          {hasMenu && 
          <BlogMenus 
          datas={datas}
          id={id}
          setCurrentPosts={setCurrentPosts} 
          title="Projects"
          />}
          
          <div className="grow-0 w-full">
            <div className="flex flex-wrap items-start justify-center gap-16 md:justify-start md:gap-16 w-full">
              {postEls}
            </div>
          </div>


        </div>
      }

        {('post-row' === template || 'realisation-row' === template) &&
        <div className="overflow-hidden w-full m-w-full">
          <swiper-container 
          init={false}
          >
            {postEls.map((postEl, index) => {

              const postType = postEl.props.type ? postEl.props.type : (postEl.props.post ? postEl.props.post.type : '' )
              const slideWidth = 'video' === postType ? 'w-96' : ('realisation' === postType ? 'w-32 mt-10' : 'w-64')
              
              return (
                <swiper-slide key={`post-${index}-wrapper`} class={slideWidth}>
                    {postEl}
                </swiper-slide>
              )
            })}
          </swiper-container>
        </div>}
        
    </section>
  )
  
}

function BlogfilterBy(posts, key, value, invertFilter) {

  if(!posts) {
    return null
  }

  if(!posts.length) {
    return null
  }

  if(!key && !value) {
    return posts
  }

  const mayInvert = (value) => {
    return true === invertFilter ? !value : value
  }
      
  let filteredPosts = posts.filter((post) => {
    
    let postProp = null
    let handle = false

    if (Object.hasOwn(post,key)) {
        postProp = post[key]
    } else if (Object.hasOwn(post,'acf') && Object.hasOwn(post['acf'],key)) {
        postProp = post['acf'][key]
    }

    if(postProp) {
      if(Array.isArray(postProp)) {// Terms
        if (true === Number.isInteger(value)) { // Term id
            handle = postProp.some(subPostProp => Object.hasOwn(subPostProp,'term_id') && value === subPostProp.term_id)
        }

        if ('string' === typeof value) {//|| Term Slug
            handle = postProp.some(subPostProp => Object.hasOwn(subPostProp,'slug') && value === subPostProp.slug)
        }
        
        if (Array.isArray(value)) { // Compare Terms
            handle = value.some(subValue =>
              Object.hasOwn(subValue,'term_id') &&
                postProp.some(subPostProp => Object.hasOwn(subPostProp,'term_id') && subValue.term_id === subPostProp.term_id)
            )
        }
      }

      if(!Array.isArray(value) && !Array.isArray(postProp)) {// Metas
          handle = value === postProp
      }
    }

    return mayInvert(handle)
  })

  return filteredPosts

}

export default Blog