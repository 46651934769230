import React, {useContext, useState} from 'react'
import {Stars} from './Stars'
import Img from './Img'
import DOMPurify from 'dompurify'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { SectionTitle } from './SectionTitle'
import Paper from '@mui/material/Paper'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DatasContext } from '../datas/DatasContext';

import {StyledDialog} from './ThemeDialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ThemeButton} from './ThemeButton';

export function Skills({sectionTitle = null, id = null}) {

    const {realisations} = useContext(DatasContext)

    let technologies = new Map()
    if(!realisations) {
        return <></>
    }
    realisations.forEach(post => {
      if (post['technology']) {
        if(post['technology'].length > 0) {
          post['technology'].forEach(term => {
              technologies.set(term.term_id, term)
          })
        }
      }
    })

    if(!technologies) {
        return <></>
    }

    technologies = Array.from(technologies.values());

    if(0 === technologies.length) {
        return <></>
    }

    return (
        <section id={id} className={`technologies w-full pt-8`}>
            <SectionTitle title={sectionTitle} />
            <div className="mx-auto w-full max-w-screen-2xl relative flex flex-wrap lg:px-4 pt-4">  
                {technologies.map((technology, index) => {
                    return <Tech key={technology.title + index} technology={technology} />
                })} 
            </div>
        </section>
    )
}

function Tech({technology}) {
    
    const {title, description, acf, image, link} = technology; 
    const techLink = link ? link : (acf ? acf.link : null);

    const imageObj = image !== undefined ? image : (acf.cover !== undefined ? acf.cover : null)
    let content = acf.content ? acf.content : description
    content = DOMPurify.sanitize(content, { ADD_TAGS: ["a","strong","em"], ADD_ATTR: ["target"], FORBID_TAGS: ["style","div","script"] })

    let backgroundColor = ''
    if(undefined !== acf ) {
        if (acf.background_color) {
            backgroundColor = acf.background_color
        }
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
 
    return(
        <>
            <div className={`basis-full lg:basis-1/2 w-full px-4 relative`}>
                <Paper className='p-4 mb-8 flex flex-wrap'>
                    <figure className={`basis-full flex cursor-pointer`} onClick={handleClickOpen}>
                        <div className={`text-center`}>
                            <span className={`w-24 h-24 m-4 block technology-picto bg-[${backgroundColor}] rounded flex items-center justify-center`}>
                                {imageObj && <Img image={imageObj} alt={title} className={`w-[80%] h-[80%] object-contain`} />}
                            </span>
                            <h3 className={`w-32 mb-0`} >
                                {title && <span className={`h-24 relative block h-6 md:px-2 flex items-center justify-center border-b border-gray-300 dark:border-gray-100 text-[12px]`}>
                                    {title}
                                </span>}
                            </h3>
                        </div>
                        <figcaption>
                            <div className={`w-full z-10 relative`}>
                                <div className="overflow-hidden w-full h-32 text-[14px] pl-4 md:px-2">
                                    <HTMLEllipsis 
                                    unsafeHTML={content}
                                    maxLine='15'
                                    ellipsis='...'
                                    basedOn='letters'
                                    />
                                </div>
                                <div className={`pl-4 md:px-2 flex items-center gap-2 justify-end h-6 block text-[12px] border-b border-gray-300 dark:border-gray-100`}>
                                    <button aria-label={`Read more details on my ${title} xp`} className={`flex items-center gap-1 underline hover:underline`}>
                                        <span>Read more</span><AddCircleOutlineIcon fontSize="inherit" />
                                    </button>
                                </div>
                            </div>
                        </figcaption>
                    </figure>
                    {acf.content_repeater && acf.content_repeater.map((vendor, index)=> {
                        return <Vendor key={vendor.title + index} vendor={vendor} />
                    })}
                </Paper>
            </div>
            <StyledDialog
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>
                    <span className="flex items-center gap-2">
                        <span className="h-6 w-6 flex items-center justify-center rounded-full border shadow-md border-gray-300 bg-slate-50">
                            {imageObj && <Img image={imageObj} alt={title} className={`w-[80%] h-[80%] object-contain rounded-full`} />}
                        </span>
                        <span className="text-[14px]">{title}</span>
                    </span>
                    <span className="flex items-center mx-2">
                        <span className="mr-2 text-[10px]">Average Skills</span>
                        <Stars rating={acf.skills ? acf.skills : 2.5} />
                    </span>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={() => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color:'#818181'
                    })}
                    >
                    <CloseIcon />
                </IconButton>
                {content && <DialogContent>
                    <div dangerouslySetInnerHTML={{__html:content}} />
                </DialogContent>}
                <DialogActions>
                    {techLink && <ThemeButton 
                    href={techLink} 
                    title={title} 
                    color="secondary"
                    target="_blank" 
                    rel="noopener noreferrer"
                    >{title} Website</ThemeButton>}
                    <ThemeButton color="grey" onClick={handleClose}>Close</ThemeButton>
                </DialogActions>
            </StyledDialog>
        </>
    )
}

function Vendor({vendor}) {
   
    const {title, acf, image, link} = vendor;
    const vendorLink = link ? link : (acf ? acf.link : null);
    let backgroundColor = ''
    if(undefined !== acf ) {
        if (acf.background_color) {
            backgroundColor = acf.background_color
        }
    }

    const imageWrapperClasses = `w-16 h-16 mx-auto technology-picto bg-[${backgroundColor}] p-2 rounded flex items-center justify-center`;

    return(
        <figure className={`basis-1/3 md:basis-1/4 max-w-32`} > 
            {vendorLink ? 
            <a title={`Visit ${title} Site`} href={vendorLink} target="_blank" rel="noopener noreferrer" className={imageWrapperClasses}>
                <Img image={image} alt={title} className={`block w-full h-full object-contain`} />
            </a> 
            : 
            <span className={imageWrapperClasses}>
                <Img image={image} alt={title} className={`block w-full h-full object-contain`} />
            </span>}
            <figcaption className='w-full'>
                <div className={`relative block h-6 pl-0 pr-2 md:px-4 flex items-center justify-center text-[12px]`}> 
                    {title && <h3>
                        {vendorLink ? 
                        <a title={`Visit ${title} Site`} href={vendorLink} target="_blank" rel="noopener noreferrer" className="underline hover:underline">
                            <span>{title}</span>&nbsp;<OpenInNewIcon fontSize="inherit" />
                        </a> 
                        : 
                        <span>{title}</span>
                        }
                    </h3>}
                </div>
            </figcaption>
        </figure>
    )
}