import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Turnstile from './TurnStile';

function Contact({ autoFocus }) {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
  const [progressIsVisible, setProgressIsVisible] = useState(false);
  const [uxFeedback, setUxFeedback] = useState(null);
  const [messagePosted, setMessagePosted] = useState(false);

  const mySubmit = async (data) => {
    setProgressIsVisible(true);

    const errorEffect = (message) => {
      setUxFeedback(message);
      setMessagePosted(false);
    };

    const validEffect = (message) => {
      setUxFeedback(message);
      setMessagePosted(true);
    };

    const userMessage = DOMPurify.sanitize(data.userMessage);
    const userName = DOMPurify.sanitize(data.userName);
    const email = DOMPurify.sanitize(data.email);
    const rgpd = data.rgpd ? true : false;
    const token = process.env.NODE_ENV === 'production' && data['token'] ? DOMPurify.sanitize(data.token) : null;

    axios.post(`${process.env.REACT_APP_BASE_URL}/api/forms`, {
      userName: userName,
      email: email,
      userMessage: userMessage,
      rgpd: rgpd,
      token: token,
    })
    .then(function (response) {
      if (response.data) {
        if (response.data.data === null && response.data.error) {
          errorEffect(response.data.error.message);
        } else {
          validEffect('Your message is on its way, I will answer you shortly.');
        }
      } else {
        errorEffect('Unreachable');
      }
    })
    .catch(function (error) {
      errorEffect(JSON.stringify(error));
    });
  };

  const ErrorWrapper = ({data}) => {
    return (<span className="block text-[10px] px-4 py-1">{data.message}</span>);
  } 

  return (
    <form onSubmit={handleSubmit(mySubmit)} className="relative">
      
      <p className="relative m-0">
        <input 
          type="text" 
          placeholder="Name" 
          autoFocus={autoFocus}
          {...register("userName", {
            required: { value: true, message: "Your name is required." }, 
            maxLength: { value: 60, message: "Your name is too long, maximum 60 characters." }
          })} 
        />
        <ErrorMessage
          errors={errors}
          name="userName"
          render={(data) => {return <ErrorWrapper data={data} className="block text-[10px] px-4 py-1" />}}
        />
        
      </p>
        
      <p className="relative m-0">
        <input 
          type="email" 
          placeholder="Email" 
          {...register("email", {
            required: { value: true, message: "Your email is required." }, 
            maxLength: { value: 100, message: "Your email is too long, maximum 100 characters." }
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={(data) => {return <ErrorWrapper data={data} className="block text-[10px] px-4 py-1" />}}
        />
      </p>

      <div className="relative">
        <textarea 
          placeholder="Your message" 
          style={{ height: 113 + 'px' }}
          {...register("userMessage", {
            minLength: { value: 100, message: "Your message is too short, minimum 100 characters." }, 
            maxLength: { value: 1200, message: "Your message is too long, maximum 1200 characters." },
            required: { value: true, message: "A message is required." }, 
          })} 
        />
        <ErrorMessage
          errors={errors}
          name="userMessage"
          render={(data) => {return <ErrorWrapper data={data} className="block text-[10px] px-4 py-1" />}}
        />
      </div>

      <label className="bg-white flex min-h-[34px]">
        <input 
          type="checkbox" 
          value="I accept Afterglow use of my data in accordance with the site's privacy policy" 
          {...register("rgpd", { required: { value: true, message: "Data treatment acceptance is required." }})}  
        />
        <span className="flex align-center">
          <span>I accept Afterglow uses my data in accordance with the <a href="/legals" title="Legals and RGPD compliance">site&apos;s privacy policy</a></span>
          <br/><ErrorMessage
          errors={errors}
          name="rgpd"
          render={(data) => {return <ErrorWrapper data={data} className="block text-[10px] px-4 py-1" />}}
          />
        </span>
      </label>

      <input 
          type="checkbox" 
          value="I accept Afterglow use of my data in accordance with the site's privacy policy" 
          {...register("rgpd", { required: { value: true, message: "Data treatment acceptance is required." }})}  
        />

      <input type="submit" disabled={!isValid} />

      <div className={`${progressIsVisible ? "opacity-100 pointer-events-all" : "opacity-0 pointer-events-none"} ease-in duration-300 flex items-center justify-center p-8 absolute top-0 left-0 w-full h-full bg-gray-200`}>
        <Box sx={{ width: '100%' }}>
          <p className='text-center text-sm'>Sending</p>
          <LinearProgress color="secondary" />
        </Box>
      </div>
      <div className={`${messagePosted === true ? "opacity-100 pointer-events-all" : "opacity-0 pointer-events-none"} from-green-200 to-green-500 text-sm leading-[1.75rem] ease-in duration-300 flex items-center justify-center p-8 absolute top-0 left-0 w-full h-full bg-gradient-to-r z-10`}>
        {uxFeedback}
      </div>
    </form>
  );
}

Contact.propTypes = {
  autoFocus: PropTypes.bool,
};

export default Contact;
