import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {ThemeButton} from './ThemeButton'
import {DatasContext} from '../datas/DatasContext'
import {ThemeDialog} from './ThemeDialog'
import Contact from './Contact'
import PropTypes from 'prop-types';

function Menu({
    menuKey, 
    className = 'block md:relative md:flex lg:flex-1 items-center justify-left px-6 lg:px-8', 
    itemClass = 'mb-8 md:mb-0', 
    linkIsMUI = true,
    linkClass = 'relative h-8 transition-all duration-300 inline-flex md:flex items-center justify-center text-sm leading-6 text-black py-3 px-4 md:mx-1 border border-gray-300 rounded-lg hover:bg-gray-100',
    fullWidth = false,
    color = null
}) {

    const {localDatas} = useContext(DatasContext)

    if(!localDatas) {
        return <></>
    } 
     
    if(!localDatas.menus) {
        return <></>
    } 
    if(!localDatas.menus[menuKey]) {
        return <></>
    } 
    const menu = localDatas.menus[menuKey]

    return (
        <ul 
        key={menuKey} 
        className={className}
        >
            {menu.map((item, index) => {

                const isContact = item.link.includes('#contact') ?? false

                return(
                    <li 
                    className={itemClass}
                    key={`${menuKey}-item-${index}`}
                    >
                        {isContact && item.hasModal ? 
                        <ThemeDialog buttonContent={item.title} title="Contact">
                            <Contact autoFocus={true} />
                            {localDatas && <Menu 
                                datas={localDatas} 
                                menuKey="contactMenu"
                                className="flex flex-wrap gap-4 py-4"
                                itemClass="mb-3 flex-1"
                                linkIsMUI={true}
                                fullWidth={true}
                                color="default"
                                />}
                        </ThemeDialog> 
                        :
                            (true === linkIsMUI ?
                            <ThemeButton
                            href={item.link}
                            title={item.title}
                            target={item.target ? item.target : ''}
                            size="small"
                            className={item.hasFeedback ? 'has-feedback' : ''}
                            fullWidth={fullWidth}
                            color={color ? color : 'default'}>
                                {item.title}
                            </ThemeButton>
                            :
                            <Link 
                            to={item.link} 
                            title={item.title} 
                            target={item.target ? item.target : ''}
                            className={fullWidth ? 'w-full ' + linkClass : linkClass}
                            reloadDocument={true}
                            preventScrollReset={false}
                            >
                                {item.title}
                            </Link>)
                        }
                    </li>
                )
            })}
        </ul>
    )
}

Menu.propTypes = {
menuKey:PropTypes.string, 
className:PropTypes.string, 
itemClass:PropTypes.string, 
linkIsMUI:PropTypes.bool,
linkClass:PropTypes.string,
fullWidth:PropTypes.bool,
color:PropTypes.string
}

export default Menu

