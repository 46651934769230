import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {wordpressPosts} from './datas/wordpressPosts';
import LocalDatas from './datas/LocalDatas';

import Home from './pages/Home';
import Page from './pages/Page';
import Realisations from './pages/Realisations';
import SingleRealisation from './pages/SingleRealisation'
import PostsBlog from './pages/PostsBlog';
import SinglePost from './pages/SinglePost';
import Error from './pages/Error';

import Header from './components/Header'
import Footer from './components/Footer'
import {ScrollToAnchor} from './components/ScrollToAnchor'
import {register} from 'swiper/element/bundle'
import {DatasContext} from './datas/DatasContext'
import {ThemePalette} from './components/ThemePalette'

import { HelmetProvider } from 'react-helmet-async';


export default function App() {
 
  const [realisations, setRealisations] = useState(null)
  const [posts, setPosts] = useState(null)
  const [pages, setPages] = useState(null)
  const [whoAmi, setwhoAmi] = useState(null)
  const [loading, setLoading] = useState(true)
  const [localDatasLoaded, setLocalDatas] = useState(null)
  const helmetContext = {};
  
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        setLocalDatas(LocalDatas);

        const realisationsDatas = await wordpressPosts('posts/realisation');
        setRealisations(realisationsDatas);

        const postsDatas = await wordpressPosts('posts/post');
        setPosts(postsDatas);

        const pagesDatas = await wordpressPosts('posts/page');
        setPages(pagesDatas);

        const whoAmiDatas = await wordpressPosts('components/whoami');
        setwhoAmi(whoAmiDatas);
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false);  
      }
    }
   
    fetchData()
   
  }, [])

  register()


  if (loading) {
    return <div className="h-[100svh] w-full transition text-black bg-gray-50 dark:text-gray-100 dark:bg-slate-950 flex justify-center items-center font-thin text-4xl "></div>
  }

  return(
      <DatasContext.Provider value={{posts:posts, pages:pages, realisations:realisations, whoAmi:whoAmi, localDatas:localDatasLoaded}} >
        <HelmetProvider context={helmetContext}>
        <ThemePalette>
          <Router>
            <Header />
            <main id="content" className="px-0 pt-10 lg:pt-12 xl:pt-16 text-gray-950 bg-slate-50 dark:text-gray-100 dark:bg-slate-950">
                <Routes>
                  <Route exact path="/" element={<Home/>} />
                  <Route path="/" element={<Home/>} />
                  <Route exact path="/projects" element={<Realisations />} />
                  <Route path="/projects/:slug" element={<SingleRealisation />} />
                  <Route exact path="/blog" element={<PostsBlog />} />
                  <Route path="/:category/:slug" element={<SinglePost />} />
                  <Route exact path="/legals" element={<Page/>} />
                  <Route exact path="/rgpd" element={<Page/>} />
                  <Route path="*" element={<Error />} />
                </Routes>
            </main>
            <Footer />
            <ScrollToAnchor offset={64} />
          </Router>
        </ThemePalette>
        </HelmetProvider>
      </DatasContext.Provider>
  )
}