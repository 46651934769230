import React, {useState, useEffect, useMemo} from 'react';
import Gallery from '../../components/Gallery';
import DOMPurify from 'dompurify';
import { ThemeAccordion } from '../../components/ThemeAccordion';
import {ThemeTabs} from '../../components/ThemeTabs';

function filterContentLang(post, lang){

  let contentParts = [];
  
  if("en" === lang) {
    if(post.translations !== null) {
      if(post.translations['en'] !== null) {
        contentParts = ParseReadmore(post.translations['en']);
      }
    }
  }
  if("fr" === lang) {
    contentParts = ParseReadmore(post.content);
  }

  let excerpt = null;
  let content = null;
  if(contentParts.length) {
    
    if(contentParts[0]) {
    excerpt = DOMPurify.sanitize(contentParts[0], { ADD_ATTR: ["target", "title"], FORBID_ATTR: ["style","class"], FORBID_TAGS: ["style","div"] })
    }

    if(contentParts[1]) {
    content = DOMPurify.sanitize(contentParts[1], { ADD_ATTR: ["target", "title"], FORBID_ATTR: ["style","class"], FORBID_TAGS: ["style","div"] })
    }

    return {content,excerpt};
  }

  return false;

  
}


export function TextContent({post, lang}) {

  const {excerpt, content} = filterContentLang(post, lang);

  if(!excerpt && !content) {
    return <></>
  }

  return(
    <div className="col-span-3 lg:col-span-1 content-wrapper">
      {excerpt ? 
      <div className="content excerpt mb-4" dangerouslySetInnerHTML={{__html:excerpt}} /> :
      <div className="content excerpt mb-4" dangerouslySetInnerHTML={{__html:content}} />
      }
      {excerpt && content &&
        <ThemeAccordion title="Readmore">
          <div className="content" dangerouslySetInnerHTML={{__html:content}} />
        </ThemeAccordion>
      }
    </div>
  )
}


export function SingleRealisationContent({post}) {

  const [imagesDesktopHeight, setImagesDesktopHeight] = useState(null);
  const [imagesMobileHeight, setImagesMobileHeight] = useState(null);
  const [sliderDesktopHeight, setSliderDesktopHeight] = useState(800);
  const [mobileSliderHeight, setSliderMobileHeight] = useState(800);
  const [galleryActiveSlide, setgalleryActiveSlide ] = useState(null);
  const [galleryIsActive, setgalleryIsActive] = useState(null);

  const imagesDesktop = post.acf.images.desktop ? post.acf.images.desktop : [];
  const imagesMobile = post.acf.images.mobile ? post.acf.images.mobile : [];

  const slides = [
    {title:'fr', content:<TextContent post={post} lang={'fr'} />}
  ];

  const swiperParams = useMemo(() => ({
    mobileBreakpoint:768,
    tabletBreakpoint:1024,
    largeScreenBreakpoint:1536,
    wrapperId: 'single-sliders-' + post.id,
    desktopViews:{
      imagesSize:{
        desktop:{ratio:0.54, screenFraction:2},
        tablet:{ratio:0.66, screenFraction:1.8},
        mobile:{ratio:0.66, screenFraction:1}
      },
      desktop:{
        slidesPerView: "auto",
        direction: "vertical",
        loop: true,
        autoplay: true,
        speed: 5000,
        autoplayDelay: 200,
        autoplayDisableOnInteraction: false,
        spaceBetween: 20,
        freeModeEnabled: true,
        mousewheelEnabled: true,
        mousewheelSensitivity: 1,
        mousewheelReleaseOnEdges: true
      },
      mobile: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: true,
        autoplay: false,
        speed:300,
        autoplayDelay: 0,
        autoplayDisableOnInteraction: false,
        spaceBetween: 5,
        centeredSlides:true,
        freeModeEnabled: false,
        mousewheelEnabled: false,
      }
    },
    mobileViews:{
      imagesSize:{
        desktop:{ratio:1.78, screenFraction:4, correction:32},
        tablet:{ratio:1.78, screenFraction:3, correction:32},
        mobile:{ratio:1.78, screenFraction:1, correction:32}
      },
      desktop:{
        slidesPerView: "auto",
        direction: "vertical",
        loop: true,
        autoplay: true,
        speed: 5000,
        autoplayDelay: 200,
        autoplayDisableOnInteraction: false,
        spaceBetween: 20,
        freeModeEnabled: true,
        mousewheelEnabled: true,
        mousewheelSensitivity: 1,
        mousewheelReleaseOnEdges: true
      },
      mobile: {
        slidesPerView: 1.2,
        direction: "horizontal",
        loop: true,
        autoplay: false,
        speed: 300,
        autoplayDelay: 0,
        autoplayDisableOnInteraction: false,
        spaceBetween: 5,
        freeModeEnabled: false,
        mousewheelEnabled: false,
      }
    },
    galleryViews:{

    }
  }), [post.id]);



  useEffect(() => {
    
    const HandleSlidesSize = () => {
    
      const windowWidth = window.innerWidth
      const desktopViewsSize = swiperParams.desktopViews.imagesSize
      const mobileViewsSize = swiperParams.mobileViews.imagesSize
      const isMobile = windowWidth < swiperParams.mobileBreakpoint
      const isTablet = windowWidth < swiperParams.tabletBreakpoint && windowWidth >= swiperParams.mobileBreakpoint
      const key = isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop')

      const desktopSliderHeight = isMobile ? ImageHeight(windowWidth, desktopViewsSize[key].ratio, desktopViewsSize[key].screenFraction) : 800
      const mobileSliderHeight = isMobile ? ImageHeight(windowWidth, mobileViewsSize[key].ratio, mobileViewsSize[key].screenFraction, mobileViewsSize[key].correction) : 800
      
      setSliderDesktopHeight(desktopSliderHeight)
      setSliderMobileHeight(mobileSliderHeight)
      setImagesDesktopHeight(ImageHeight(windowWidth, desktopViewsSize[key].ratio, desktopViewsSize[key].screenFraction))
      setImagesMobileHeight(ImageHeight(windowWidth, mobileViewsSize[key].ratio, mobileViewsSize[key].screenFraction, mobileViewsSize[key].correction))

    }

    HandleSlidesSize()
    window.addEventListener('resize', HandleSlidesSize)
    return () => window.removeEventListener('resize', HandleSlidesSize)

  }, [swiperParams, setSliderDesktopHeight, setSliderMobileHeight, setImagesDesktopHeight, setImagesMobileHeight]);

  useEffect(() => {

    const DesktopViewsSlider = document.querySelector('#'+swiperParams.wrapperId + ' .desktop-slider swiper-container ')
    const MobileViewsSlider = document.querySelector('#'+swiperParams.wrapperId + ' .mobile-slider swiper-container ')
    
    const handleBreakpoint = () => {
      let desktopViewsArgs = {}
      let mobileViewsArgs = {}
      
        if(window.innerWidth > swiperParams.mobileBreakpoint) {
          desktopViewsArgs = swiperParams.desktopViews.desktop
          mobileViewsArgs = swiperParams.mobileViews.desktop
        } else {
          desktopViewsArgs = swiperParams.desktopViews.mobile
          mobileViewsArgs = swiperParams.mobileViews.mobile
        }

      if(DesktopViewsSlider ) {
        Object.assign(DesktopViewsSlider, desktopViewsArgs)
      }
      if(MobileViewsSlider) {
        Object.assign(MobileViewsSlider, mobileViewsArgs)
      }
    }

    handleBreakpoint()
    window.addEventListener('resize', () => {
      handleBreakpoint()  
    })

    if(DesktopViewsSlider ) {
      DesktopViewsSlider.initialize()
    }
    if(MobileViewsSlider ) {
      MobileViewsSlider.initialize()
    }

  }, [swiperParams]);

  if(post.translations) {
    if(post.translations['en']) {
      const enContent = <TextContent post={post} lang={'en'} />;
      if (enContent) {
        slides.unshift({title: 'en', content: enContent});
      }
    }
  }

  const handleOpenGallery = (e) => {
    setgalleryActiveSlide(e.target.dataset.id)
    setgalleryIsActive(true)
  }

  return(
    <div id={swiperParams.wrapperId} className="w-full py-4 md:py-10 lg:pt-12 xl:pt-16">
        <div className="px-4 md:px-6 lg:px-8 mx-auto w-full max-w-screen-2xl lg:grid lg:grid-cols-3 lg:gap-4 xl:grid-cols-4 relative">
          {imagesDesktop.length > 0 && (
            <div className="mb-5 lg:mb-0 xl:col-span-2 desktop-slider">
              <swiper-container 
              init={false}
              style={{
                height: sliderDesktopHeight + 'px'
              }}
              >
                {imagesDesktop.map((image) => {
                  return (
                    <swiper-slide key={image.id} style={{height: imagesDesktopHeight + 'px'}}>
                        <img
                          src={image.url}
                          alt={image.caption}
                          data-id={image.id}
                          className="w-full h-full cursor-pointer object-contain"
                          onClick={handleOpenGallery}
                        />
                    </swiper-slide>
                  )
                })}
              </swiper-container>
            </div>
          )}

          {imagesMobile.length > 0 && (
            <div className="mb-5 lg:mb-0 mobile-slider">
              <swiper-container 
              init={false}
              className="mobile-slider"
              style={{
                height: mobileSliderHeight + 'px',
              }}
              >
                {imagesMobile.map((image) => {
                  return (
                  <swiper-slide key={image.id} style={{height: imagesMobileHeight + 'px'}}>
                    <img
                      src={image.url}
                      alt={image.caption}
                      data-id={image.id}
                      className="w-full h-full cursor-pointer object-contain"
                      onClick={handleOpenGallery}
                    />
                  </swiper-slide>
                  )
                })}
              </swiper-container>
            </div>
          )}

          {slides && 
          <ThemeTabs tabs={slides} filterTabContent={false} smallTabs={true} />}
        </div>
        {(imagesDesktop && imagesMobile) && 
        <Gallery 
        galleryIsActive={galleryIsActive} 
        setgalleryIsActive={setgalleryIsActive} 
        galleryActiveSlide={galleryActiveSlide} 
        title={post.title} 
        imagesDesktop={imagesDesktop}
        imagesMobile={imagesMobile}
         />}
    </div>
  )
}

function ImageHeight(width, ratio, widthFraction, correction = 0) {
  if(width < 1536) {
    return (width - 64 - 8 - correction) / widthFraction * ratio
  }
  return (1536 - 64 - 8 - correction) / widthFraction * ratio
}

function ParseReadmore(string) {
  const regex = /(?:<p>)?(<!--more-->|<!-- more -->)(?:<\/p>)?/
  const matches = string.match(regex)
  if(matches) {
    return string.split(matches[0])
  }
  return [string]
}