import React, {useContext} from 'react'
import Menu from './Menu'
import Logo from './Logo'
import {DatasContext} from '../datas/DatasContext'
import {Link} from 'react-router-dom'

export default function Footer() {

    const {localDatas} = useContext(DatasContext)

    return (
        <footer className="bg-gray-200 text-sm leading-6 text-gray-900" role="contentinfo" id="main-footer">
            <div className="mx-auto flex max-w-screen-2xl p-4 sm:p-6 lg:p-8">
                <div className="w-8">
                    <Logo/>
                    <div className="social"></div>
                </div>
                <div className="w-80">
                    <Menu 
                    datas={localDatas} 
                    menuKey="mainMenu"
                    className="block"
                    linkIsMUI={false}
                    linkClass="block px-1 mx-8 mb-4 border-b border-gray-950"
                    />
                </div>
                <div className="w-32 md:w-auto">
                    <Menu 
                    datas={localDatas} 
                    menuKey="footerCtaMenu"
                    className="block md:flex md:gap-4"
                    itemClass="mb-4 w-32"
                    size="large"
                    fullWidth={true}
                    />
                </div>
            </div>
            <p className="mx-auto text-right max-w-screen-2xl py-2 px-4 sm:px-6 lg:px-8 text-[10px] mb-0">
                All rights reserved, <Link to={localDatas.site.hostname} title={localDatas.site.title}>{localDatas.site.title}</Link> — {new Date().getFullYear()} — <a className={`underline hover:underline`} href="/legals" title="Legals">Legals</a> 
            </p>
        </footer>
    )
}