import React, { useState, useEffect } from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Img({image, alt, className = '', loadingLazy = false, style = null}) {

const [imageSrc, setImageSrc] = useState(null)


let src = null
let orientation = 'landscape'
if(image) {
  if(Object.hasOwn(image,'src')) {
    src = image.src
  }
  if(Object.hasOwn(image,'url')) {
    src = image.url
  }

  if(image.width && image.height) {
    orientation = image.width <= image.height ? 'portrait' : 'landscape'
  }
}

useEffect(() => {
  if(!src) {
    return
  }
  const srcParts = src.split('uploads/')

  if(srcParts[1]) {

    (async () => {
      try {
        const response = await axios.get(`/api/medias/${srcParts[1]}`)
        setImageSrc(response.data)
      } catch (error) {
        console.error(error)
      }
    })()
  } else {
    setImageSrc(src);
  }

}, [src])

const handleError = (error) => {
  console.log(error);
}

const spread = {}
if(loadingLazy) {
  spread.loading = 'lazy'
} 


return (
      <>
          {imageSrc ? 
          <img 
          onError={handleError} 
          src={imageSrc} 
          alt={alt ? alt : (image.caption ? image.caption : null)} 
          className={`${orientation} ${className}`}
          style={style}
          {...spread}
          /> :
          <Box sx={{ 
              display: 'flex',
              opacity:'35%',
           }}>
              <CircularProgress color="default"/>
          </Box>
          }
      </>
  )

}

Img.propTypes = {
  image:PropTypes.object,
  alt:PropTypes.string,
  className:PropTypes.string,
  loadingLazy:PropTypes.bool,
  style:PropTypes.object,
}

export default Img;