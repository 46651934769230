import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const StyledFormControl = styled(FormControl)((props) => ({
  display:'block',
  [props.theme.breakpoints.down('md')]:{
      display:'flex',
      flexWrap:'nowrap',
      width:'100%',
  },
}))

StyledFormControl.propTypes = {
  theme: PropTypes.shape({
    breakpoints: PropTypes.shape({
        down: PropTypes.func,
    }),
  }),
}

export const StyledFormLabel = styled(FormLabel)(props => (
  {
    fontSize: '10px',
    fontFamily:'ui-sans-serif, system-ui, sans-serif',
    color:'#020617',
    textTransform:'uppercase',
    padding:'2px',
    borderTop:'1px solid gray',
    borderBottom:'1px solid gray',
    height:'25px',
    letterSpacing:'.2rem',
    display:'inline-flex',
    alignItems:'center',
    marginBottom:'8px',
    with:'auto',
    '&.Mui-focused':{
      color:'#020617',
    },
    [props.theme.breakpoints.down('md')]:{
        border:'unset',
        textTransform:'unset',
        fontSize:'14px',
        letterSpacing:'0',
        padding:'4px 8px',
        height:'auto',
        pointerEvents:'none',
    },
  }
))

StyledFormLabel.propTypes = {
  theme: PropTypes.shape({
      breakpoints: PropTypes.shape({
          down: PropTypes.func,
      }),
  }),
};

export const StyledRadioGroup = styled(RadioGroup)((props) => (
  {
    paddingLeft:'4px',
    display:'flex',
    flexWrap:'wrap',
    width:'100%',
    gap:'4px',
    [props.theme.breakpoints.down('md')]:{
        paddingLeft:'12px',
        justifyContent:'space-between'
    },
  }
))

StyledRadioGroup.propTypes = {
  theme: PropTypes.shape({
      breakpoints: PropTypes.shape({
          down: PropTypes.func,
      }),
  }),
};

export const StyledFormControlLabel = styled(FormControlLabel)(props => (
  {
    display: 'inline-flex',
    flexGrow: 0,
    width: 'auto',
    textTransform: 'capitalize',
    padding: '4px 12px 4px 0px',
    borderRadius: '20px',
    height: '34px',
    backgroundColor:[props.checked ? props.theme.palette.secondary.main : props.theme.palette.primary.dark],
    color:[props.checked ? props.theme.palette.secondary.contrastText : props.theme.palette.primary.contrastText],
    '.MuiTypography-root': {
      fontSize:'14px',
      fontFamily: 'inherit',
    },
    '&:last-child':{
      marginRight:0
    }
  }
))

StyledFormControlLabel.propTypes = {
  checked: PropTypes.bool,
  theme: PropTypes.shape({
      palette: PropTypes.shape({
          secondary: PropTypes.shape({
              main: PropTypes.string,
              contrastText: PropTypes.string,
          }),
          primary: PropTypes.shape({
              dark: PropTypes.string,
              contrastText: PropTypes.string,
          }),
      }),
  }),
};

export const StyledRadio = styled(Radio)(props => (
  {
    '.MuiSvgIcon-root':{
      fontSize: '14px',
      backgroundColor:props.theme.palette.secondary.contrastText,
      borderRadius:'100%',
      color: [props.checked ? props.theme.palette.secondary.main : props.theme.palette.secondary.contrastText]
    },
    '.RadioButtonCheckedIcon':{
      fontSize: '14px',
      color: [props.checked ? '#f476cf':props.theme.palette.secondary.contrastText]
    }
  }
))

StyledRadio.propTypes = {
  checked: PropTypes.bool,
  theme: PropTypes.shape({
      palette: PropTypes.shape({
          secondary: PropTypes.shape({
              main: PropTypes.string,
              contrastText: PropTypes.string,
          }),
      }),
  }),
};

export function ThemeRadioGroup({name, values, onChange, reset,  currentValue}) {
  
  return (
    <StyledFormControl>
      <StyledFormLabel id={name}>Work Context</StyledFormLabel>
      <StyledRadioGroup
        aria-labelledby={name}
        name={name}
        defaultValue="all"
        row={true}
      >
          <StyledFormControlLabel 
          value="all" 
          onClick={() => reset()}
          checked={null === currentValue} 
          control={<StyledRadio size="small" checked={null === currentValue} />} 
          label="all"
          aria-label={`All posts`}
          />
        {values.map((value, index) => {
          return(<StyledFormControlLabel 
          key={name + value + index}
          value={value}
          checked={value === currentValue} 
          onChange={ (e) => onChange({name:name,value:value,event:e}) }
          control={<StyledRadio size="small" checked={value === currentValue} />} 
          label={value} 
          sx={{fontSize:'14px'}}
          aria-label={`Filter posts by ${value}`}
          />)
        })}
        
      </StyledRadioGroup>
    </StyledFormControl>
  );
}

ThemeRadioGroup.propTypes = {
  name:PropTypes.string, 
  values:PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.number), PropTypes.arrayOf(PropTypes.object)]), 
  onChange:PropTypes.func, 
  reset:PropTypes.func,  
  currentValue:PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]), 
}