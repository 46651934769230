import React, { useEffect } from 'react';

export function ScrollToAnchor({offset}) {

  useEffect(() => {
    const onLoad = () => {
      if(window.location.hash) {
        const hrefParts = window.location.hash.split('#');
        if (!hrefParts[1]) {
          return
        }

        const targetElement = document.getElementById(hrefParts[1])
        if (!targetElement) {
          return
        }
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    onLoad()
  },[])
  
  useEffect(() => {

    let anchorDatas = []

    const findAnchors = () => {
      const foundAnchors = document.querySelectorAll('a[href*="/#"]');
      if (!foundAnchors) {
        return
      }
  
      foundAnchors.forEach((anchor, index) => {
        
        const href = anchor.getAttribute('href');
        if (!href) {
          return
        }
  
        const hrefParts = href.split('#');
        if (!hrefParts[1]) {
          return
        }
  
        const targetElement = document.getElementById(hrefParts[1])
        if (!targetElement) {
          return
        }
  

        anchor.addEventListener('click', (e) => {
          e.preventDefault();
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        })

        if(anchor.classList.contains('has-feedback')) {
          anchorDatas.push({
            anchor:anchor,
            target: targetElement,
          })
        }

      })

    }
  
    const onScroll =  () => {
        
      window.addEventListener('scroll', () => {
        
        const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
        const vh = document.documentElement.clientHeight;
        
        anchorDatas.forEach((anchorData, index) => {
            
            const isLast = index + 1 === anchorDatas.length
            const isFirst = 0 === index


            const targetPosition = anchorData.target.getBoundingClientRect()
            if(targetPosition.top + scrollY <= scrollY && targetPosition.bottom + scrollY > scrollY) {
              anchorData.anchor.setAttribute('style','background-color:#adffc7;')
            } else {
              anchorData.anchor.setAttribute('style','background-color:#e5e7eb;')
            }

            if(isLast && (targetPosition.bottom + scrollY - vh) < scrollY) {
              anchorDatas.forEach((anchorData, index) => {
                anchorData.anchor.setAttribute('style','background-color:#e5e7eb;')
              })
              anchorData.anchor.setAttribute('style','background-color:#adffc7;')
            }
            
            if(isFirst && (targetPosition.top + scrollY) > scrollY) {
              anchorDatas.forEach((anchorData, index) => {
                anchorData.anchor.setAttribute('style','background-color:#e5e7eb;')
              })
              anchorData.anchor.setAttribute('style','background-color:#adffc7;')
            }
   
        })

      }, { capture: true, passive: true })
    }

    findAnchors()
    if (!anchorDatas) {
      return
    }
    onScroll()

    return () => {
      window.removeEventListener('scroll', onScroll)
    }

  },[])

  return <></>

}