import React, {useContext} from 'react'
import Contact from './Contact'
import Menu from './Menu'
import {DatasContext} from '../datas/DatasContext'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles';

export function ThemeContact({className='p-4', autoFocus = false}) {

    const {localDatas} = useContext(DatasContext)

    const StyledPaper = styled(Paper)(props => ({
        backgroundColor:'#adffc7'
    }))

    return (
        <StyledPaper className={className}>
            {localDatas && 
            <Menu 
            datas={localDatas} 
            menuKey="contactMenu"
            className="flex flex-wrap gap-4 pb-4"
            itemClass="mb-3 flex-1"
            linkIsMUI={true}
            fullWidth={true}
            color="default"
            />}
            <Contact autoFocus={autoFocus} />
        </StyledPaper>
    )

}