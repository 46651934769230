import React from 'react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles';

const StyledFab = styled(Fab)(({ theme }) => ({
    display:'flex',
    color: theme.palette.getContrastText('#e5e7eb'),
    backgroundColor: '#e5e7eb',
    '&:hover': {
        backgroundColor: '#adffc7',
        color: theme.palette.getContrastText('#adffc7'),
    },
    textTransform: 'none',
    fontFamily: [
        'inherit',
        ].join(','),
    fontWeight:'400'
}))

const ActionStyledFab = styled(Fab)(({ theme }) => ({
    display:'flex',
    color: theme.palette.getContrastText('#adffc7'),
    backgroundColor: '#adffc7',
    '&:hover': {
        backgroundColor: '#adffc7',
        color: theme.palette.getContrastText('#adffc7'),
    },
    textTransform: 'none',
    fontFamily: [
        'inherit',
        ].join(','),
    fontWeight:'400'
}))

export function ThemeButton({children,...props}) { 
    
    props.size = !props.size ? "small":props.size
    props.variant = !props.variant ? "contained":props.variant
    
    return (
        <Button sx={{
            textTransform: 'none',
            fontWeight:'400'
        }} {...props}>
            {children}
        </Button>
    )
}

export function RoundedButton({children,...props}) { 
    
    props.size = !props.size ? "small":props.size
    props.variant = !props.variant ? "contained":props.variant
    
    return (
        <Button sx={{
            borderRadius:'17px',
            height:'34px',
            textTransform: 'none',
            fontFamily: 'inherit',
            fontWeight:'400'
            }} {...props}>
            {children}
        </Button>
    )
}

export function FloatingButton({children,...props}) {

    props.size = !props.size ? "medium":props.size
    props.variant = !props.variant ? "extended":props.variant
    
    
    const Fab = props.template === 'action' ?
        <ActionStyledFab {...props}>
            {children}
        </ActionStyledFab>
        :
        <StyledFab {...props}>
            {children}
        </StyledFab>

    return Fab;

}