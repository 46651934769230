import React from 'react'
import DOMPurify from 'dompurify'
import { ThemeAccordion } from '../ThemeAccordion'
import { PostLink } from './PostLink'
import VideoPlayer from '../VideoPlayer'
import {postTypes,videoTypes} from '../../datas/types.js'

Video.propTypes = {
    post:postTypes
}
useVideoDatas.propTypes = {
    post:postTypes
}
VideoMetas.propTypes = {
    video:videoTypes
}
VideoTags.propTypes = {
    video:videoTypes
}

export function Video({post}) {

    

    const videoProps = useVideoDatas(post)

    if(!videoProps) {
        return <></>
    }
    videoProps.propTypes = videoTypes
    
    return(
        <article id={videoProps.id} className={`video w-96 mb-8 overlfow-hidden`}>
            <VideoMetas video={videoProps} />
            <VideoPlayer video={videoProps} />
            <VideoTags video={videoProps} />
            <figcaption id={`video-caption-${videoProps.id}`}>
                {videoProps.content &&
                    <ThemeAccordion title="Details">
                        <div className="content" dangerouslySetInnerHTML={{__html:videoProps.content}} />
                        <div className="pt-2 flex justify-end">
                            <PostLink post={videoProps} isButton={true}>
                            Read
                            </PostLink>
                        </div>
                    </ThemeAccordion>
                }
            </figcaption>
        </article>
    )
}

export function useVideoDatas(post) {
    if(!post) {
        return false
    }

    const video = post.video
    if(!video) {
        return false
    }
    const categories = post.categories
    const slug = post.slug
    const content = post.content

    let firstCategory = 'blog'
    if(categories) {
        if(categories.length > 0) {
            firstCategory = categories[0].slug
        }
    }

    let props = {
        id: null,
        type:null,
        slug:null,
        categories:null,
        title : null,
        channelTitle: null,
        content : null,
        thumbnail : null,
        tags: null,
        publishedAt : null,
        duration : null,
        viewsCount : null,
        videoElId : null,
        localLink: null
    }

    if(video !== false && video !== null && video !== undefined) {
        try {
            props = {
                id: video.id,
                type:'video',
                slug:slug,
                categories:categories,
                title : video.snippet.title,
                channelTitle: video.snippet.channelTitle,
                content : DOMPurify.sanitize(content, { FORBID_ATTR: ["style","class"], FORBID_TAGS: ["style","div"] }),
                thumbnail : video.snippet.thumbnails.standard,
                tags:video.snippet.tags,
                publishedAt : new Date(video.snippet.publishedAt).toDateString().slice(4),
                duration : youtubeDurationToHuman(video.contentDetails.duration),
                viewsCount : video.statistics.viewCount,
                videoElId :`video-${video.id}`,
                localLink: '/' + firstCategory +'/'+ slug
            }
        } catch (error) {


        }
        
    }

    return props
}

export function VideoMetas({video}) {
 
    return (
        <>
        {video &&
         <>
        <h3 className="block p-1 border-t border-b border-gray-500  text-[14px] tracking-wider h-[30px] truncate">
            <PostLink post={video}>
            <span>{video.channelTitle}</span> • {video.title && <span>{video.title}</span>}
            </PostLink>
        </h3>
        <p className="flex justify-between p-1 text-[12px]">
            <span>{video.publishedAt}</span>
            <span>{video.viewsCount} views</span>
            <span>{video.duration}</span>
        </p>
        </>
        }
        </>
    )

}

export function VideoTags({video}) {
    return (
    <>
        {video.tags && <p className="text-[10px] m-0 p-1 border-b border-gray-500 h-[25px] truncate">
            in {video.tags.length > 0 && video.tags.map(tag =>{
                return <span key={video.id + tag} className="inline-block mr-1">#{tag}</span>
            })}
        </p>}
    </>
    )
}

function youtubeDurationToHuman(duration) {
    const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(\d+)S/);
    const hours = match[1] ? parseInt(match[1]) : 0;
    const minutes = match[2] ? parseInt(match[2]) : 0;
    const seconds = parseInt(match[3]);

    let durationString = '';
    if (hours > 0) {
        durationString += `${hours} hr${hours !== 1 ? 's.' : '.'}`;
    }
    if (minutes > 0) {
        if (durationString) durationString += ' ';
        durationString += `${minutes} min${minutes !== 1 ? 's.' : '.'}`;
    }
    if (seconds > 0 || durationString === '') {
        if (durationString) durationString += ' ';
        durationString += `${seconds} sec${seconds !== 1 ? 's.' : '.'}`;
    }
    return durationString
}
