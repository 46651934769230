import React from 'react'
import { Link } from 'react-router-dom'
import Img from '../Img'
import {postTypes} from '../../datas/types.js'

PostLogo.propTypes = {
    post:postTypes
}

RealisationLogo.propTypes = {
    post:postTypes
}

export function PostLogo({post}) {

    return(
        <div className="mx-auto animate-logo realisation-dark-hover dark:shadow-realisation-light-hover border-2 lg:border-1 lg:shadow-realisation-dark hover:shadow-realisation-dark-hover dark:shadow-realisation-light dark:hover:shadow-realisation-light-hover shrink-0 box-border block relative basis-[100px] w-[100px] h-[100px] transition-all overflow-hidden rounded-xl border border-slate-950 dark:border-gray-100 shadow-md bg-gray-50">
            <figure className="t-0 l-0 w-full h-full absolute">
                {'realisation' === post.type ? 
                <RealisationLogo post={post} /> 
                : 
                (post.featured_media ? 
                    <Img image={post.featured_media} alt={post.title} className="h-full w-full object-cover" /> : '')
                }
            </figure>
        </div>
    )
}

function RealisationLogo({post}) {

    let logoGrid = {logo:null,color_1:null,color_2:null,color_3:null}

    if(post.acf.logo_grid) {
        logoGrid = post.acf.logo_grid
    }

    if(!logoGrid['logo']) {
        return <></>
    }

    return(
        <Link to={`/projects/${post.slug}`} reloadDocument={true} preventScrollReset={false} className="flex w-[100px] h-[100px]" title={post.title}>
            <div className="logo-wrapper w-[75px] shrink-0">
                <div 
                style={null !== logoGrid.color_1 ? {
                    backgroundColor:logoGrid.color_1
                } : ''}
                className="logo-wrapper flex items-center justify-center w-full h-[75px]"
                >
                    {null !== logoGrid.logo.url &&
                    <Img 
                    image={logoGrid.logo} 
                    alt={`Logo ${post.title}`} 
                    loadingLazy={true}
                    className="logo"
                    style={null !== logoGrid.logo_size && {
                        width:logoGrid.logo_size + 'px',
                        height:logoGrid.logo_size + 'px'
                    }}
                    />}
                </div>
                <div 
                style={null !== logoGrid.color_2 ? {
                    backgroundColor:logoGrid.color_2
                } : {}}
                className="block border-t-2 border-slate-950 dark:border-gray-100 w-full h-[23px] hover-h-0" 
                ></div>
            </div>
            <div 
            style={null !== logoGrid.color_3 ? {
                backgroundColor:logoGrid.color_3
            }: {}}
            className="block shrink-0 border-l-2 border-slate-950 dark:border-gray-100 w-[23px] hover-w-0 h-[100px]" 
            ></div>
        </Link>
    )
}