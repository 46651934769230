import React from 'react'
import PropTypes from 'prop-types';
import {postTypes,termTypes} from '../../datas/types.js'

Terms.propTypes = {
    post:postTypes,
    taxonomy:PropTypes.string,
    terms:PropTypes.arrayOf(termTypes),
    before:PropTypes.string,
    prefix:PropTypes.string
}
export function Terms({post = null, taxonomy = null, terms = null, before = null, prefix = null}) {

    if(null === post && null === taxonomy && null === terms) {
        return <></>
    }

    let foundTerms = null

    if(null !== terms) {
        foundTerms = terms
    }

    if(null !== post && null !== taxonomy) {
        if(undefined !== post[taxonomy]) {
            foundTerms = post[taxonomy]
        }
    }
   
    if(foundTerms === undefined || foundTerms === null || foundTerms === false) {
        return <></>
    }

    return(
        <p className={`${null !== taxonomy ? taxonomy : ""} m-0 p-1 border-gray-500 border-t border-b h-[26px] truncate`}>
            {null !== before && <span className="before">{before}</span>}
            {foundTerms.map((term) => (
                <span key={`${taxonomy}-term-${term.id}`} className="inline-block mr-1">
                    {null !== prefix && <span className="prefix">{prefix}</span>}
                    {term.name}
                </span>
            ))}
        </p>
    )
}