const LocalDatas = {
    site:{
        title: "Afterglow Web Agency",
        description: "A FullStack Web Applications Developer",
        fullTitle: "Afterglow Web Agency | A FullStack Web Applications Developer",
        hostname:window.location.protocol + "//" + window.location.host
    },
    menus: {
        mainMenu:[
            {title:'Projects', link:'/#projects', hasFeedback:true},
            {title:'Services', link:'/#services', hasFeedback:true},
            {title:'Skills', link:'/#skills', hasFeedback:true},
            {title:'Playlist', link:'/#playlist', hasFeedback:true},
        ],
        ctaMenu:[
            {title:'Contact', link:'#contact', hasModal:true},
            {title:'Github', link:'https://github.com/AfterglowWeb', target:'_blank'},
        ],
        footerCtaMenu:[
            {title:'Contact', link:'#contact', hasModal:true},
            {title:'Github', link:'https://github.com/AfterglowWeb', target:'_blank'},
            {title:'Linkedin', link:'https://www.linkedin.com/in/c%C3%A9dric-moris-kelly-bbb22861/', target:'_blank'},
        ],
        contactMenu:[
            {title:'Linkedin', link:'https://www.linkedin.com/in/c%C3%A9dric-moris-kelly-bbb22861/', target:'_blank'},
            {title:'Github', link:'https://github.com/AfterglowWeb', target:'_blank'},
        ]
    },
    sections:{
        technologies:{
            title:'Techs',
            subtitle:'',
            children:[
                {
                    title:'More',
                    details:'',
                    children:[
                    {title:'Debian, Raspbian, Shell automations, Deployment automations'},
                    {title:'Figma, XD, Illustrator, Photoshop'},
                    {title:'Agile Methods, Miro.com, Redmine, Slack, Teams, Trello'}
                ]}
            ]   
        }
    }
};

export default LocalDatas;