import React, {useContext} from 'react'
import { useLocation } from 'react-router-dom';
import { DatasContext } from '../../datas/DatasContext';
import {HtmlHead} from '../../components/HtmlHead';
import Blog from '../../components/Blog';
import WhoAmi from '../../components/WhoAmi';
import {ThemeBreadcrumbs} from '../../components/ThemeBreadcrumbs';
import DOMPurify from 'dompurify';
import {ThemeTabs} from '../../components/ThemeTabs';
import {TextContent} from '../SingleRealisation/SingleRealisationContent';

export default function Page() {

    const {pages} = useContext(DatasContext);
    const location = useLocation();
    const pageSlug = String(location.pathname).split('/');

    if(!pageSlug[1]) {
      return  <></>
    }
    const page = pages.find(page => pageSlug[1] === page.slug);
    
    if(!page) {
      return <></>
    }

    let pageContent = <></>
    if(page.content) {
      pageContent = DOMPurify.sanitize(page.content, { FORBID_ATTR: ["style","class"], FORBID_TAGS: ["style","div"] })
    }

    let slides = null;

    if(page.translations) {
      if(page.translations['en']) {

        slides = [
          {title:'fr', content:<TextContent post={page} lang={'fr'} />}
        ];

        const enContent = <TextContent post={page} lang={'en'} />;
        if (enContent) {
          slides.unshift({title: 'en', content: enContent});
        }
      }
    }

    return (
      <>
      <HtmlHead title={page.title} description={page.excerpt} canonical={'/'+page.slug} />
      <article id={`post-${page.id}`} className="">
        <div className="mx-auto w-full max-w-3xl content p-8">
          {slides ? 
          <ThemeTabs tabs={slides} filterTabContent={false} smallTabs={true} /> : 
          pageContent
          }
        </div>
      </article>
      <Blog id="single-playlist" postType="post" template="post-row" className="py-8" samePostTemplate={false} sectionTitle="Blog readings" />
      <WhoAmi />
      <ThemeBreadcrumbs post={page} />
      </>
    )
  }
